<template>
    <div
        :class="{ 'active': isActive }"
        class="menu-component"
    >
        <ul class="menu-component__menu-list">
            <li
                :class="{ 'active': activeMenuItemRouteName === menuItem.to.name }"
                :key="index"
                @click="menuItemClickHandler"
                class="menu-component__menu-item"
                v-for="(menuItem, index) in menuItems"
            >
                <router-link
                    :to="menuItem.to"
                    class="menu-component__menu-item-link"
                >
                    {{ menuItem.label }}
                </router-link>
            </li>
        </ul>
        <slot name="append"/>
    </div>
</template>

<script>
    import {computed} from 'vue';
    import {useRoute} from 'vue-router';
    import {useStore} from 'vuex';

    export default {
        props: {
            isActive: {
                type: Boolean,
                default: false,
            },
        },
        emits: {
            menuItemSelect: () => true,
        },
        setup(props, context) {
            const route = useRoute();
            const store = useStore();

            const menuItems               = computed(() => store.getters['MENU/ITEMS']);
            const activeMenuItemRouteName = computed(() => route.name);

            const menuItemClickHandler = () => context.emit('menuItemSelect');

            return {
                menuItems,
                activeMenuItemRouteName,
                menuItemClickHandler,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .menu-component {
        position: absolute;
        z-index: 3;
        top: $header__height_mobile;
        left: 0;
        width: 100%;
        padding: 30px 0;
        transition: transform .1s linear;
        transform: scaleY(0);
        transform-origin: top;
        background: $menu__background;

        &.active {
            transform: scaleY(1);
        }

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            position: static;
            display: flex;
            align-items: center;
            transform: scaleY(1);
            background: $menu__background_tablet;
        }
    }

    .menu-component__menu-list {
        @media screen and (min-width: $breakpoint_tablet_navbar) {
            display: flex;
            gap: 30px;
        }
    }

    .menu-component__menu-item {
        display: block;
        height: 40px;
        padding-left: 15px;
        cursor: pointer;
        color: $menu-item__color;
        background: $menu-item__background;

        &:hover {
            background: $menu-item__background_hover;
        }

        &.active {
            color: $menu-item__color_active;

            &::after {
                background: $menu-item__background_active;
            }
        }

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            position: relative;
            height: unset;
            padding: 0;

            &::after {
                position: absolute;
                bottom: -2px;
                width: 100%;
                height: 2px;
                content: '';
                transition: transform .2s ease-out;
                transform: scale(0);
                background: $menu-item__underline__background;
            }

            &:hover {
                background: unset;

                &::after {
                    transform: scale(1);
                }
            }
        }

        @media screen and (min-width: $breakpoint_desktop) {
            &::after {
                height: 3px;
            }
        }
    }

    .menu-component__menu-item-link {
        font-size: 1rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        text-decoration: none;

        @media screen and (min-width: $breakpoint_desktop) {
            font-size: 1.5rem;
        }
    }
</style>
