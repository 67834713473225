import api from './index';

export default {
    async getDistricts() {
        const response = await api().get('/districts');

        return response.data.data;
    },
    async getApiInfo() {
        const response = await api().get('/info');
        
        return response.data;
    },
};
