<template>
    <div
        class="accessibility"
        v-if="ENABLED"
    >
        <div class="accessibility-font">
            <p class="accessibility-font__title">
                Размер шрифта:
            </p>
            <div
                :class="{
                    'active': FONT_SIZE.value === item.value,
                    [ 'accessibility-font__element_' + item.value ]: true
                }"
                :key="index"
                @click="setFontSize(item.setMutation)"
                class="accessibility-font__element"
                v-for="(item, index) in AVAILABLE_FONT_SIZES"
            >А
            </div>
        </div>
        <div class="accessibility-color-theme">
            <p class="accessibility-color-theme__title">
                Цвет:
            </p>
            <div
                :class="{
                    'active': COLOR_THEME.value === item.value,
                    [ 'accessibility-color-theme__element_' + item.value ]: true
                }"
                :key="index"
                @click="setColorTheme(item.setMutation)"
                class="accessibility-color-theme__element"
                v-for="(item, index) in AVAILABLE_COLOR_THEMES"
            >
                <span class="accessibility-color-theme__dot"></span>
            </div>
        </div>
        <div class="accessibility-images">
            <p class="accessibility-images__title">
                Изображения:
            </p>
            <label class="accessibility-images__element-label">
                <input
                    :value="true"
                    type="radio"
                    v-model="IMAGES_SHOWED"
                />
                Вкл.
            </label>
            <label class="accessibility-images__element-label">
                <input
                    :value="false"
                    type="radio"
                    v-model="IMAGES_SHOWED"
                />
                Выкл.
            </label>
        </div>
        <div class="accessibility-kerning">
            <p class="accessibility-kerning__title">
                Кернинг:
            </p>
            <div
                :class="{
                    'active': KERNING.value === item.value,
                    [ 'accessibility-kerning__element_' + item.value ]: true
                }"
                :key="index"
                @click="setKerningSize(item.setMutation)"
                class="accessibility-kerning__element"
                v-for="(item, index) in AVAILABLE_KERNING_SIZES"
            >АT
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex';

    export default {
        name: 'AccessibilityComponent',
        computed: {
            ...mapGetters({
                ENABLED: 'accessibility/ENABLED',
                FONT_SIZE: 'accessibility/FONT_SIZE',
                KERNING: 'accessibility/KERNING',
                COLOR_THEME: 'accessibility/COLOR_THEME',
                AVAILABLE_FONT_SIZES: 'accessibility/AVAILABLE_FONT_SIZES',
                AVAILABLE_KERNING_SIZES: 'accessibility/AVAILABLE_KERNING_SIZES',
                AVAILABLE_COLOR_THEMES: 'accessibility/AVAILABLE_COLOR_THEMES',
            }),
            IMAGES_SHOWED: {
                get() {
                    return this.$store.getters['accessibility/IMAGES_SHOWED'];
                },
                set(value) {
                    if (value) {
                        this.SHOW_IMAGES();
                    } else {
                        this.HIDE_IMAGES();
                    }

                    this.setImagesShowed();
                },
            }
        },
        mounted() {
            if (this.ENABLED) {
                this.setFontSize('SET_FONT_SIZE_MEDIUM');
                this.setColorTheme('SET_COLOR_THEME_WHITE');
                this.setImagesShowed();
            }
        },
        watch: {
            ENABLED(value) {
                if (value) {
                    this.setFontSize('SET_FONT_SIZE_MEDIUM');
                    this.setColorTheme('SET_COLOR_THEME_WHITE');
                    this.setImagesShowed();
                } else {
                    const html = document.getElementsByTagName('html')[0];

                    [...html.classList].forEach(item => {
                        html.classList.remove(item)
                    })
                }
            }
        },
        methods: {
            ...mapMutations({
                ENABLE: 'accessibility/ENABLE',
                DISABLE: 'accessibility/DISABLE',
                SHOW_IMAGES: 'accessibility/SHOW_IMAGES',
                HIDE_IMAGES: 'accessibility/HIDE_IMAGES',
            }),
            setFontSize(setFontSizeMutation) {
                const html = document.getElementsByTagName('html')[0];

                this.$store.commit(`accessibility/${setFontSizeMutation}`);
                window.requestAnimationFrame(() => {
                    this.AVAILABLE_FONT_SIZES.forEach(item => html.classList.remove(item.class));

                    html.classList.add(this.FONT_SIZE.class);
                });
            },
            setColorTheme(setColorThemeMutation) {
                const html = document.getElementsByTagName('html')[0];

                this.$store.commit(`accessibility/${setColorThemeMutation}`);
                window.requestAnimationFrame(() => {
                    this.AVAILABLE_COLOR_THEMES.forEach(item => html.classList.remove(item.class));

                    html.classList.add(this.COLOR_THEME.class);
                });
            },
            setKerningSize(setKerningSizeMutation) {
                const html = document.getElementsByTagName('html')[0];

                this.$store.commit(`accessibility/${setKerningSizeMutation}`);
                window.requestAnimationFrame(() => {
                    this.AVAILABLE_KERNING_SIZES.forEach(item => html.classList.remove(item.class));

                    html.classList.add(this.KERNING.class);
                });
            },
            setImagesShowed() {
                const html = document.getElementsByTagName('html')[0];

                window.requestAnimationFrame(() => {
                    if (this.IMAGES_SHOWED) {
                        html.classList.remove('accessibility__images_hidden');
                    } else {
                        html.classList.add('accessibility__images_hidden');
                    }
                });
            },
        },
    };
</script>

<style lang="scss">
    .accessibility {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 5px;
        border-bottom: 1px solid black;
        background-color: $white;
        gap: 5px;

        @media screen and (min-width: $breakpoint_desktop) {
            flex-direction: row;
            gap: 20px;
        }
    }

    .accessibility-font,
    .accessibility-color-theme,
    .accessibility-images,
    .accessibility-kerning {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .accessibility-font__element,
    .accessibility-color-theme__element,
    .accessibility-kerning__element {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        cursor: pointer;
        border: 2px solid $black;
    }

    .accessibility-kerning__element {
        width: 40px;
        height: 30px;
    }

    .accessibility-font__element {
        &:hover, &.active {
            color: $white;
            background: $black;
        }
    }

    .accessibility-font__element_small {
        font-size: 12px;
    }

    .accessibility-font__element_large {
        font-size: 20px;
    }

    .accessibility-color-theme__dot {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    .accessibility-color-theme__element_white {
        background: $white;

        .accessibility-color-theme__dot {
            background-color: $black;
        }
    }

    .accessibility-color-theme__element_black {
        background: $black;

        .accessibility-color-theme__dot {
            background-color: $white;
        }
    }

    .accessibility-color-theme__element_blue {
        background: $blue-light;

        .accessibility-color-theme__dot {
            background-color: $blue;
        }
    }

    .accessibility-kerning__element_small {
        letter-spacing: 0.03rem;
    }

    .accessibility-kerning__element_medium {
        letter-spacing: 0.1rem;
    }

    .accessibility-kerning__element_large {
        letter-spacing: 0.2rem;
    }
</style>
