<template>
    <table
        @mousedown="mouseDownHandler"
        @mousemove="mouseMoveHandler"
        class="table-component"
        ref="tableRef"
    >
        <thead class="table-component__head">
        <tr class="table-component__row">
            <th :data-column-name="column.name" :key="'column_' + index" class="table-component__head-cell"
                v-for="(column, index) in columns">
                <span class="text">{{ column.value || column.name }}</span>
            </th>
            <th class="table-component__head-cell" v-if="Boolean($slots.append)">
                <span class="text"></span>
            </th>
        </tr>
        </thead>
        <tbody class="table-component__body">
        <tr :key="'row_' + rowIndex" class="table-component__row" v-for="(row, rowIndex) in rows">
            <td :data-column-name="column.name" :data-label="column.value || column.name"
                :key="'cell_' + rowIndex + '_' + columnIndex" class="table-component__cell"
                v-for="(column, columnIndex) in columns">
                    <span class="text" v-if="column.name == 'data_reg_ceny_i_nomer_ru'">
                        {{ row[ column.name ].split(' ')[0] || '&mdash;' }}
                        <a rel="nofollow noopener"
                            target="_blank"
                            class="table-component__cell-link"
                            v-bind:href="codeUrl + row[ 'nomer_ru' ]">
                            {{ row[ 'nomer_ru' ] || '&mdash;' }}
                        </a>
                    </span>
                <span class="text" v-else-if="column.name == 'roznichnaya_cena'">
                    {{ row[ column.name ] || '&mdash;' }}
                    <span class="cell-help" @click="showModal"></span>
                </span>
                <span class="text" v-else>{{ row[ column.name ] || '&mdash;' }}</span>
            </td>
            <td class="table-component__cell" data-label="">
                <slot :row="row" name="append"/>
            </td>
        </tr>
        </tbody>
    </table>
    <vue-modal
        @close="isNotFindModalShowed = false"
        v-if="isNotFindModalShowed"
    >
        <p class="app__modal-text">
            Актуальность цены определяется датой вступления в силу приказа и датой выпуска ЛП, подробная информация в разделе "Как это работает?"
        </p>
    </vue-modal>
</template>

<script>
    import {nextTick, ref, toRefs, watch, computed} from 'vue';
    import {useStore} from 'vuex';
    import Modal from "@/components/Modal.vue";

    export default {
        components: {
            'vue-modal': Modal,
        } ,

        props: {
            columns: {
                type: Array,
                default: null,
                validator: (columns) => columns.every(column => 'name' in column),
            },
            rows: {
                type: Array,
                default: null,
            },
        },
        emits: {
            sortASC: () => true,
            sortDESC: () => true,
        },
        setup(props, context) {
            const {columns} = toRefs(props);
            const tableRef  = ref(null);

            const sortASC  = (payload) => context.emit('sortASC', payload);
            const sortDESC = (payload) => context.emit('sortDESC', payload);

            const store  = useStore();
            const codeUrl = computed(() => store.getters['CODEURL']);

            watch(columns, (newColumns, oldColumns) => {
                if (newColumns.length > oldColumns.length) {
                    const addedColumnNames = [];
                    const oldColumnNames   = oldColumns.map(item => item.name);

                    if (oldColumns.length === 0) {
                        return;
                    }

                    for (let newColumn of newColumns) {
                        if (!oldColumnNames.includes(newColumn.name)) {
                            addedColumnNames.push(newColumn.name);
                        }
                    }

                    nextTick(() => {
                        for (let addedColumnName of addedColumnNames) {
                            const addedCells = document.querySelectorAll(`[data-column-name=${addedColumnName}]`);

                            addedCells.forEach((addedCell) => {
                                if (addedCell.tagName === 'TH' && addedCell.offsetLeft + addedCell.offsetWidth > tableRef.value.offsetWidth) {
                                    tableRef.value.scrollTo({
                                        left: addedCell.offsetLeft + addedCell.offsetWidth - tableRef.value.offsetWidth,
                                        behavior: 'smooth'
                                    });
                                }

                                addedCell.style['animation'] = 'column 1s ease-out 0s';
                            });
                        }
                    });
                } else if (newColumns.length < oldColumns.length) {
                    const removedColumnNames = [];
                    const newColumnNames     = newColumns.map(item => item.name);

                    for (let oldColumn of oldColumns) {
                        if (!newColumnNames.includes(oldColumn.name)) {
                            removedColumnNames.push(oldColumn.name);
                        }
                    }

                    for (let removedColumnName of removedColumnNames) {
                        const removedCells = document.querySelectorAll(`[data-column-name=${removedColumnName}]`);

                        removedCells.forEach((removedCell) => {
                            if (removedCell.tagName === 'TH' && tableRef.value.scrollLeft) {
                                const newScrollPosition = tableRef.value.scrollLeft - removedCell.offsetWidth

                                nextTick(() => {
                                    tableRef.value.scrollTo({
                                        left: Math.max(newScrollPosition, 0),
                                        behavior: 'smooth'
                                    });
                                })
                            }

                            removedCell.style['animation'] = null;
                        });
                    }
                }
            });

            const mouseDownPosition = {
                scrollLeft: null,
                scrollTop: null,
                clientX: null,
                clientY: null,
            };

            const mouseDownHandler = (event) => {
                mouseDownPosition.scrollLeft = tableRef.value.scrollLeft;
                mouseDownPosition.scrollTop  = tableRef.value.scrollTop;
                mouseDownPosition.clientX    = event.clientX;
                mouseDownPosition.clientY    = event.clientY;

                if (!event.target.classList.contains('text')) {
                    window.getSelection().empty();
                }
            };

            const mouseMoveHandler = (event) => {
                const leftMouseButtonCode = 1;

                if (!event.target.classList.contains('text') && !window.getSelection().toString() && event.buttons === leftMouseButtonCode) {
                    const dx = event.clientX - mouseDownPosition.clientX;
                    const dy = event.clientY - mouseDownPosition.clientY;

                    window.requestAnimationFrame(() => {
                        tableRef.value.scrollLeft = mouseDownPosition.scrollLeft - dx;
                        tableRef.value.scrollTop  = mouseDownPosition.scrollTop - dy;
                    });
                }
            };
            const isNotFindModalShowed  = ref(null);

            const showModal = () => {
                isNotFindModalShowed.value = true;
            } ;

            return {
                tableRef,
                mouseDownHandler,
                mouseMoveHandler,
                sortASC,
                sortDESC,
                isNotFindModalShowed,
                showModal,
                codeUrl,
            };
        },
    };
</script>

<style lang="scss">
    @keyframes column {
        0% {
            background: transparent;
            color: $table__keyframe-column_0__text-color;
        }

        50% {
            background: $table__keyframe-column_50__background;
            color: $table__keyframe-column_50__text-color;
        }

        100% {
            background: transparent;
            color: $table__keyframe-column_0__text-color;
        }
    }

    .table-component {
        display: block;
        max-width: 100%;
        cursor: move;
        cursor: -moz-grab;

        @media screen and (min-width: $breakpoint_desktop) {
            display: table;
            display: block;
            overflow-x: auto;
            border-collapse: collapse;
            border-style: hidden;
            border-radius: 10px;
            box-shadow: 0 0 0 1px $table__border-color;
        }

        &:active {
            cursor: grabbing;
            user-select: none;
        }

        .table-component__head-cell,
        .table-component__cell {
            .text {
                cursor: text;
                user-select: text;
            }
        }
    }

    .table-component__head {
        display: none;

        @media screen and (min-width: $breakpoint_desktop) {
            display: table-header-group;
        }
    }

    .table-component__body {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        gap: 30px;

        @media screen and (min-width: $breakpoint_desktop) {
            display: table-row-group;
        }
    }

    .table-component__row {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        max-width: 100%;
        border: 1px solid $table__border-color;
        border-radius: 7px;
        background-color: $table__background;

        td[data-column-name="roznichnaya_cena"], td[data-column-name="data_vstuplenia_w_sily"],
        th[data-column-name="roznichnaya_cena"], th[data-column-name="data_vstuplenia_w_sily"] {
            background-color: #7C97AE;
            color: white;
        }

        @media screen and (min-width: $breakpoint_tablet) {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
        }

        @media screen and (min-width: $breakpoint_desktop) {
            display: table-row;
            border-radius: none;

            &,
            & .table-component__cell:last-child,
            & .table-component__head-cell:last-child {
                border-right: none;
            }

            &,
            & .table-component__cell:first-child,
            & .table-component__head-cell:first-child {
                border-left: none;
            }

            &:first-child,
            &:first-child .table-component__cell,
            &:first-child .table-component__head-cell {
                border-top: none;
            }

            td[data-column-name="vladelec"] {
            max-width: 230px;
            }

            td[data-column-name="data_reg_ceny_i_nomer_ru"] {
                max-width: 125px;
            }
        }
    }

    .table-component__body .table-component__row {
        @media screen and (min-width: $breakpoint_desktop) {
            &:last-child,
            &:last-child .table-component__cell,
            &:last-child .table-component__head-cell {
                border-bottom: none;
            }
        }
    }

    .table-component__head-cell {
        font-size: 0.875rem;
        font-weight: 500;
        position: relative;
        padding: 10px 15px;
        vertical-align: middle;
        border: 1px solid $table__border-color;
    }

    .table-component__cell {
        padding: 10px 15px;
        text-align: center;
        word-wrap: break-word;
        position: relative;


        .cell-help {
            position: absolute;
            top: 4px;
            right: 4px;
            padding: 2px 6px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            background-image: url('~@/assets/images/question_2.png');

            &:hover {
               background-image: url('~@/assets/images/question_1.png');
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $table__border-color;
        }

        &::before {
            font-size: 0.875rem;
            font-weight: 500;
            display: block;
            margin-bottom: 5px;
            content: attr(data-label);
            text-align: center;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            padding: 20px;
            border: 1px solid $table__border-color;

            &::before {
                display: none;
            }


        }

        .table-component__cell-link {
            cursor: pointer;
            color: #0A865A;

            &:hover {
                color: #37C47A;
            }
        }
    }
</style>
