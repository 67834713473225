const state = {
    enabled: false,
    imagesShowed: true,
    fontSize: 'medium',
    colorTheme: 'white',
    kerning: 'small',
    availableFontSizes: [
        {
            value: 'small',
            class: 'accessibility__font-size__small',
            setMutation: 'SET_FONT_SIZE_SMALL',
        }, {
            value: 'medium',
            class: 'accessibility__font-size__medium',
            setMutation: 'SET_FONT_SIZE_MEDIUM',
        }, {
            value: 'large',
            class: 'accessibility__font-size__large',
            setMutation: 'SET_FONT_SIZE_LARGE',
        }
    ],
    availableColorThemes: [
        {
            value: 'white',
            class: 'accessibility__color-theme__white',
            setMutation: 'SET_COLOR_THEME_WHITE',
        }, {
            value: 'black',
            class: 'accessibility__color-theme__black',
            setMutation: 'SET_COLOR_THEME_BLACK',
        }, {
            value: 'blue',
            class: 'accessibility__color-theme__blue',
            setMutation: 'SET_COLOR_THEME_BLUE',
        }
    ],
    availableKerningSizes: [
        {
            value: 'small',
            class: 'accessibility__kerning__small',
            setMutation: 'SET_KERNING_SMALL',
        }, {
            value: 'medium',
            class: 'accessibility__kerning__medium',
            setMutation: 'SET_KERNING_MEDIUM',
        }, {
            value: 'large',
            class: 'accessibility__kerning__large',
            setMutation: 'SET_KERNING_LARGE',
        }
    ],
};

const mutations = {
    ENABLE(state) {
        state.enabled = true;
    },
    DISABLE(state) {
        state.enabled = false;
    },
    SHOW_IMAGES(state) {
        state.imagesShowed = true;
    },
    HIDE_IMAGES(state) {
        state.imagesShowed = false;
    },
    SET_FONT_SIZE_SMALL(state) {
        state.fontSize = 'small';
    },
    SET_FONT_SIZE_MEDIUM(state) {
        state.fontSize = 'medium';
    },
    SET_FONT_SIZE_LARGE(state) {
        state.fontSize = 'large';
    },
    SET_KERNING_SMALL(state) {
        state.kerning = 'small';
    },
    SET_KERNING_MEDIUM(state) {
        state.kerning = 'medium';
    },
    SET_KERNING_LARGE(state) {
        state.kerning = 'large';
    },
    SET_COLOR_THEME_WHITE(state) {
        state.colorTheme = 'white';
    },
    SET_COLOR_THEME_BLACK(state) {
        state.colorTheme = 'black';
    },
    SET_COLOR_THEME_BLUE(state) {
        state.colorTheme = 'blue';
    },
};

const getters = {
    ENABLED: (state) => {
        return state.enabled;
    },
    IMAGES_SHOWED: (state) => {
        return state.imagesShowed;
    },
    FONT_SIZE: (state) => {
        return state.availableFontSizes.find(item => item.value === state.fontSize);
    },
    KERNING: (state) => {
        return state.availableKerningSizes.find(item => item.value === state.kerning);
    },
    COLOR_THEME: (state) => {
        return state.availableColorThemes.find(item => item.value === state.colorTheme);
    },
    AVAILABLE_FONT_SIZES: (state) => {
        return state.availableFontSizes;
    },
    AVAILABLE_KERNING_SIZES: (state) => {
        return state.availableKerningSizes;
    },
    AVAILABLE_COLOR_THEMES: (state) => {
        return state.availableColorThemes;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
