<template>
    <div class="about-view">
        <vue-breadcrumbs :items="[{ label: 'О сервисе' }]"/>
        <h1 class="about-view__title">О СЕРВИСЕ</h1>
        <table class="about-view__table">
            <thead class="about-view__table-head">
            <tr class="about-view__table-row">
                <th class="about-view__table-head-cell">№ п.п.</th>
                <th class="about-view__table-head-cell">Сокращение</th>
                <th class="about-view__table-head-cell">Расшифровка</th>
            </tr>
            </thead>
            <tbody class="about-view__table-body">
            <tr class="about-view__table-row">
                <td class="about-view__table-cell" data-label="№ п.п."></td>
                <td class="about-view__table-cell" data-label="Сокращение">АИС «ЛОД»</td>
                <td class="about-view__table-cell" data-label="Расшифровка">
                    Автоматизированная информационная система лицензирования отдельных
                    видов деятельности
                </td>
            </tr>
            <tr class="about-view__table-row">
                <td class="about-view__table-cell" data-label="№ п.п."></td>
                <td class="about-view__table-cell" data-label="Сокращение">Сервис</td>
                <td class="about-view__table-cell" data-label="Расшифровка">
                    Сервис проверки предельной розничной цены на лекарственные препараты
                    из Государственного реестра цен на жизненно необходимые и важнейшие
                    лекарственные препараты (далее - ЖНВЛП) с возможностью загрузки файла
                    данных с перечнем наименования препаратов из Государственного реестра
                    цен на ЖНВЛП
                </td>
            </tr>
            <tr class="about-view__table-row">
                <td class="about-view__table-cell" data-label="№ п.п."></td>
                <td class="about-view__table-cell" data-label="Сокращение">ЖНВЛП</td>
                <td class="about-view__table-cell" data-label="Расшифровка">Жизненно необходимые и важнейшие
                    лекарственные препараты
                </td>
            </tr>
            <tr class="about-view__table-row">
                <td class="about-view__table-cell" data-label="№ п.п."></td>
                <td class="about-view__table-cell" data-label="Сокращение">МНН</td>
                <td class="about-view__table-cell" data-label="Расшифровка">Международное непатентованное наименование
                </td>
            </tr>
            <tr class="about-view__table-row">
                <td class="about-view__table-cell" data-label="№ п.п."></td>
                <td class="about-view__table-cell" data-label="Сокращение">Пользователь</td>
                <td class="about-view__table-cell" data-label="Расшифровка">Любой участник Сервиса</td>
            </tr>
            <tr class="about-view__table-row">
                <td class="about-view__table-cell" data-label="№ п.п."></td>
                <td class="about-view__table-cell" data-label="Сокращение">Интернет-браузер</td>
                <td class="about-view__table-cell" data-label="Расшифровка">
                    Клиентская программа, поставляемая третьими сторонами и позволяющая
                    просматривать содержимое HTML-страниц
                </td>
            </tr>
            </tbody>
        </table>
        <div class="about-view__content">
            <h3 class="about-view__subtitle">Введение</h3>
            <p class="about-view__text">
                Автоматизированная информационная система лицензирования отдельных видов
                деятельности (АИС «ЛОД»).
            </p>
            <p class="about-view__text">
                Областью применения АИС «ЛОД» является автоматизация оказания государственных
                услуг по лицензированию отдельных видов деятельности. Объектами автоматизации
                АИС «ЛОД» являются процессы оказания региональными органами исполнительной
                власти услуг по лицензированию отдельных видов деятельности.
            </p>
            <p class="about-view__text">
                В составе АИС «ЛОД» разработан Сервис проверки предельной розничной цены на
                лекарственные препараты из Государственного реестра цен на жизненно необходимые
                и важнейшие лекарственные препараты с возможностью загрузки файла данных сперечнем
                наименования препаратов из Государственного реестра цен на ЖНВЛП (Далее - Сервис).
            </p>
            <h3 class="about-view__subtitle">Главный экран Сервиса</h3>
            <ul class="about-view__list">
                <p class="about-view__list-text">Главный экран Сервиса содержит следующие разделы (Рис. 1):</p>
                <li class="about-view__list-item">«Главная»;</li>
                <li class="about-view__list-item">«Проверки»;</li>
                <li class="about-view__list-item">«О сервисе»;</li>
                <li class="about-view__list-item">«Окно поиска»;</li>
                <li class="about-view__list-item">«Раздел выбора районов Томской области».</li>
            </ul>
            <figure class="about-view__figure">
                <img alt="Главный экран Сервиса" class="about-view__figure-image" src="@/assets/images/main-screen.jpg">
                <figcaption class="about-view__figure-caption">Рис. 1 &mdash; Главный экран Сервиса</figcaption>
            </figure>
            <p class="about-view__text">
                Раздел «Главная» является интерактивной web-страницей Сервиса, предназначен для
                быстрого перехода на главную страницу Сервиса.
            </p>
            <p class="about-view__text">
                Раздел «Проверки» предназначен для отображения проведенных проверок обращений
                пользователей Сервиса.
            </p>
            <p class="about-view__text">
                Раздел «О сервисе» предназначен для размещения пояснительной информации о работе
                Сервиса для пользователей.
            </p>
            <p class="about-view__text">
                «Окно поиска» является основным используемым элементом интерактивной web-страницы
                Сервиса. Предназначено для поиска предельной розничной цены на лекарственные
                препараты из Государственного реестра цен на ЖНВЛП.
            </p>
            <ul class="about-view__list">
                <p class="about-view__list-text">
                    Для осуществления поиска предельной розничной цены на лекарственные препараты
                    из Государственного реестра цен на ЖНВЛП ввести в «Окно поиска» запрос,
                    содержащий хотя бы один из следующих параметров фильтра:
                </p>
                <li class="about-view__list-item">Штрих - код;</li>
                <li class="about-view__list-item">МНН;</li>
                <li class="about-view__list-item">Торговое наименование;</li>
                <li class="about-view__list-item">Форма выпуска лекарственного препарата;</li>
                <li class="about-view__list-item">Производитель лекарственного препарата.</li>
            </ul>
            <p class="about-view__text">Выбрать кнопку «Найти».</p>
            <p class="about-view__text">
                Ответ на запрос в интерактивной web-странице отобразится в табличном виде с
                перечнем препаратов, подходящих под условия заполненного фильтра.
            </p>
            <ul class="about-view__list">
                <p class="about-view__list-text">Перечень препаратов, формируемый к отображению, выполняет следующие
                    условия:</p>
                <li class="about-view__list-item">Полное совпадение Штрих-кода (цифрового значения);</li>
                <li class="about-view__list-item">Полное совпадение торгового наименования лекарственного препарата;
                </li>
                <li class="about-view__list-item">Предельная розничная цена.</li>
            </ul>
            <p class="about-view__text">
                Раздел выбора районов Томской области предназначен для сортировки лекарственных
                препаратов по стоимости. В зависимости от того, где продается лекарственный препарат,
                формируется его окончательная цена. Суровые климатические и природные условия в
                районах Крайнего Севера оказывают серьезное влияние на организм человека. Работа в
                таких условиях не является приоритетной у большинства населения страны. В целях
                стимулирования и развития трудовой деятельности на территории таких районов был
                разработан специальный инструмент – северная надбавка. Северные надбавки – это
                коэффициенты, которые увеличивают заработную плату сотрудникам. Их используют для
                начисления выплат лицам, которые осуществляют свою трудовую деятельность на
                территориях Крайнего Севера, а также в районах, приравненных к ним. Устанавливается
                согласно: Приказ Департамента тарифного регулирования Томской области от 28.01.2021
                № 10-2 "Об установлении на территории Томской области предельных размеров оптовых
                надбавок и предельных размеров розничных надбавок к фактическим отпускным ценам (без
                учета налога на добавленную стоимость), установленным производителями лекарственных
                препаратов, на лекарственные препараты, включенные в перечень жизненно необходимых и
                важнейших лекарственных препаратов".
            </p>
        </div>
    </div>
</template>

<script>
    import BreadcrumbsVue from '@/components/Breadcrumbs';

    export default {
        components: {
            'vue-breadcrumbs': BreadcrumbsVue,
        },
    };
</script>

<style lang="scss" scoped>
    .about-view {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px 15px 15px;

        @media screen and (min-width: $breakpoint_tablet) {
            padding: 10px 50px 15px;
        }
    }

    .about-view__title {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 25px;
        text-align: center;
    }

    .about-view__description {
        font-size: 1rem;
        font-weight: 400;
        max-width: 600px;
        margin-bottom: 30px;
    }

    .about-view__table {
        display: block;
        max-width: 100%;

        @media screen and (min-width: $breakpoint_desktop) {
            display: block;
            overflow-x: auto;
            border-collapse: collapse;
            border-style: hidden;
            border-radius: 10px;
            box-shadow: 0 0 0 1px $table__border-color;
        }
    }

    .about-view__table-head {
        display: none;

        @media screen and (min-width: $breakpoint_desktop) {
            display: table-header-group;
        }
    }

    .about-view__table-body {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        gap: 30px;

        @media screen and (min-width: $breakpoint_desktop) {
            display: table-row-group;
        }
    }

    .about-view__table-row {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        max-width: 100%;
        border: 1px solid $table__border-color;
        border-radius: 7px;
        background-color: $table__background;

        @media screen and (min-width: $breakpoint_tablet) {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
        }

        @media screen and (min-width: $breakpoint_desktop) {
            display: table-row;
            border-radius: none;

            &,
            & .about-view__table-cell:last-child,
            & .about-view__table-head-cell:last-child {
                border-right: none;
            }

            &,
            & .about-view__table-cell:first-child,
            & .about-view__table-head-cell:first-child {
                border-left: none;
            }

            &:first-child,
            &:first-child .about-view__table-cell,
            &:first-child .about-view__table-head-cell {
                border-top: none;
            }

            &:last-child,
            &:last-child .about-view__table-cell {
                border-bottom: none;
            }
        }
    }

    .about-view__table-head-cell {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 10px 15px;
        vertical-align: middle;
        border: 1px solid $table__border-color;
    }

    .about-view__table-cell {
        padding: 10px 15px;
        text-align: center;
        word-wrap: break-word;

        &:not(:last-child) {
            border-bottom: 1px solid $table__border-color;
        }

        &::before {
            font-size: 0.875rem;
            font-weight: 500;
            display: block;
            margin-bottom: 5px;
            content: attr(data-label);
            text-align: center;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            padding: 20px;
            border: 1px solid $table__border-color;

            &::before {
                display: none;
            }
        }
    }


    .about-view__content {
        max-width: 100%;
    }

    .about-view__subtitle {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 25px 0 15px;
    }

    .about-view__text {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        margin: 15px 0;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1rem;
        }
    }

    .about-view__list {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        margin: 10px 0;
        list-style: disc;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1rem;
        }
    }

    .about-view__list-text {
    }

    .about-view__list-item {
        margin-left: 20px;
    }

    .about-view__figure {
        max-width: 100%;
        margin: 20px 20px;
    }

    .about-view__figure-image {
        max-width: 100%;
        border: 1px solid $image__border-color;
    }

    .about-view__figure-caption {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 5px;
        text-align: center;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1rem;
        }
    }
</style>
