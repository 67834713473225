<template>
    <div class="search-input-component">
        <input
            :class="{
                'moved-placeholder': hasValue,
                'bottom-borderless': suggestionsLoading || hasSuggestions,
            }"
            @input="onInputHandler"
            @keyup="suggestionNavigationHandler"
            autocomplete="off"
            class="component__input-field"
            id="search-input"
            ref="searchInputRef"
            tabindex="1"
            type="text"
            v-model="localModelValue"
        />
        <label
            class="component__input-field-label"
            for="search-input"
        >
        </label>
        <label
            class="component__clear-button-label"
            for="search-input"
        >
            <button
                @click="clearInput"
                class="component__clear-button"
            >
            </button>
            <span
                @click="advancedInput"
                class="component__advanced-button"
            >
            </span>
        </label>
        <label
            class="component__suggestions-label"
            for="search-input"
            v-if="suggestionsLoading || hasSuggestions"
        >
            <ul
                class="component__suggestions"
                v-if="!suggestionsLoading && hasSuggestions"
            >
                <li
                    :class="{ 'focused': focusedSuggestionIndex === index }"
                    :data-index="'suggestion_' + index"
                    :key="'component__suggestions-item_' + index"
                    @click.prevent="onSelectSuggestionHandler(suggestions[index])"
                    @mouseenter="clearFocusedSuggestionIndex"
                    class="component__suggestions-item"
                    v-for="(visibleSuggestion, index) in visibleSuggestions"
                >
                    <p class="suggestions-item__name" v-html="visibleSuggestion.text"></p>
                    <p class="suggestions-item__field">
                        {{ visibleSuggestion.field }}
                    </p>
                </li>
            </ul>
            <div
                class="component__suggestions-label-loader"
                v-if="suggestionsLoading"
            >
                <img
                    alt="Загрузка"
                    class="suggestions-label-loader__icon"
                    src="@/assets/images/loading_200x200.gif"
                />
            </div>
        </label>
    </div>
</template>

<script>
    import {computed, ref} from 'vue';
    import useLocalModelValue from '@/composables/useLocalModelValue'

    export default {
        props: {
            modelValue: {
                type: String,
                default: '',
            },
            suggestions: {
                type: Array,
                default: null,
            },
            suggestionsLoading: {
                type: Boolean,
                default: false,
            },
        },
        emits: {
            ['update:modelValue']: () => true,
            ['input']: () => true,
            ['select']: () => true,
            ['submit']: () => true,
            ['clear']: () => true,
        },
        setup(props, context) {
            const searchInputRef         = ref(null);
            const focusedSuggestionIndex = ref(null);

            const localModelValue = useLocalModelValue(props, context);

            const visibleSuggestions = computed(() => {
                return props.suggestions.map((suggestion) => {
                    const field                 = Object.keys(suggestion.search_result)[0];
                    const {start, end, ru_name} = suggestion.search_result[field];

                    const value             = String(suggestion[field]);
                    const startString       = value.slice(0, start);
                    const coincidenceString = value.slice(start, end);
                    const endString         = value.slice(end);

                    return {
                        text: `${startString}<b style="font-weight: 700;">${coincidenceString}</b>${endString}`,
                        field: ru_name,
                    };
                });
            });

            const hasValue       = computed(() => localModelValue.value.length > 0);
            const hasSuggestions = computed(() => visibleSuggestions.value.length > 0);

            const onInputHandler            = () => context.emit('input', localModelValue);
            const onSelectSuggestionHandler = (payload) => context.emit('select', payload);
            const clearInput                = () => context.emit('clear');
            const advancedInput             = () => {
                context.emit('advanced')
            };

            const suggestionNavigationHandler = (event) => {
                switch (event.code) {
                    case 'ArrowUp':
                        suggestionArrowUpNavigationHandler();
                        break;
                    case 'ArrowDown':
                        suggestionArrowDownNavigationHandler();
                        break;
                    case 'Enter':
                        suggestionEnterNavigationHandler();
                        break;
                }

                scrollToFocusedSuggestion();
            };

            const suggestionArrowUpNavigationHandler = () => {
                if (Number.isInteger(focusedSuggestionIndex.value) && focusedSuggestionIndex.value > 0) {
                    focusedSuggestionIndex.value -= 1;
                }
            };

            const suggestionArrowDownNavigationHandler = () => {
                if (!Number.isInteger(focusedSuggestionIndex.value)) {
                    focusedSuggestionIndex.value = 0;
                } else if (focusedSuggestionIndex.value < props.suggestions.length - 1) {
                    focusedSuggestionIndex.value += 1;
                }
            };

            const suggestionEnterNavigationHandler = () => {
                if (props.suggestions[focusedSuggestionIndex.value]) {
                    context.emit('submit', props.suggestions[focusedSuggestionIndex.value]);

                    clearFocusedSuggestionIndex();

                    searchInputRef.value.blur();
                }
            };

            const scrollToFocusedSuggestion = () => {
                const focusedSuggestionDataIndex = 'suggestion_' + focusedSuggestionIndex.value;
                const focusedSuggestionSelector  = `[data-index='${focusedSuggestionDataIndex}']`
                const focusedSuggestionElement   = document.querySelector(focusedSuggestionSelector);

                if (focusedSuggestionElement) {
                    focusedSuggestionElement.scrollIntoView(false);
                }
            };

            const clearFocusedSuggestionIndex = () => {
                focusedSuggestionIndex.value = null;
            };

            return {
                hasValue,
                hasSuggestions,
                localModelValue,
                visibleSuggestions,
                onInputHandler,
                onSelectSuggestionHandler,
                clearInput,
                advancedInput,
                suggestionNavigationHandler,
                focusedSuggestionIndex,
                searchInputRef,
                clearFocusedSuggestionIndex,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .search-input-component {
        position: relative;
        height: 60px;
        margin-bottom: 20px;
    }

    .component__input-field {
        font-size: 1rem;
        font-weight: 700;
        width: 100%;
        height: 100%;
        padding: 25px 50px 0 13px;
        transition: border-radius .1s linear;
        color: $search-input__text-color;
        border: 1px solid $search-input__border-color;
        border-radius: 10px;
        outline: none;

        &.moved-placeholder + .component__input-field-label,
        &:focus + .component__input-field-label {
            font-size: 11px;
            line-height: 10px;
            transform: translateY(15%);

            @media screen and (min-width: $breakpoint_tablet) {
                font-size: 0.75rem;
                transform: translateY(5%);
            }
        }

        &:focus ~ .component__suggestions-label {
            visibility: visible;
        }

        &:focus.bottom-borderless {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.3rem;
            padding: 15px 50px 0 13px;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            font-size: 1.625rem;
            padding: 15px 50px 0 25px;
        }
    }

    .component__input-field-label {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        position: absolute;
        top: 0;
        left: 15px;
        display: block;
        display: flex;
        align-items: center;
        width: calc(100% - 65px);
        height: 50%;
        cursor: text;
        transition: all .2s ease-out;
        transform: translateY(50%);
        color: $search-input__label__text-color;

        &::before {
            content: "Введите Штрих-код (цифры под штрих-кодом)";
        }

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1rem;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            left: 25px;
        }

        @media screen and (max-width: $breakpoint_mobile) {
            &::before {
            content: "Введите Штрих-код";
        }
        }
    }

    .component__clear-button-label {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;

        &::before {
            position: absolute;
            top: 1px;
            left: -20px;
            display: block;
            width: 20px;
            height: calc(100% - 2px);
            content: "";
            background: $search-input__clear-button__background_pseudo;
        }

        &:hover {
            .component__clear-button {
                background: $search-input__clear-button__background_hover;

                &::before, &::after {
                    background: $search-input__clear-button__background_hover_pseudo;
                }
            }
        }
    }

    .component__clear-button {
        position: relative;
        width: 30px;
        height: 30px;
        margin: 0 10px;
        cursor: pointer;
        transition: background .1s ease-out;
        border: 2px solid $search-input__clear-button__background;
        border-radius: 50%;
        outline: none;
        background: transparent;

        &::before, &::after {
            position: absolute;
            top: calc(20%);
            left: calc(50% - 1px);
            display: block;
            width: 2px;
            height: 60%;
            content: "";
            transition: background .1s ease-out;
            background: $search-input__clear-button__background;
        }

        &::before {
            transform: rotateZ(45deg);
        }

        &::after {
            transform: rotateZ(-45deg);
        }
    }

    .component__advanced-button {
        display: none;

        @media screen and (max-width: $breakpoint_tablet) {
            position: relative;
            margin-right: 10px;
            width: 40px;
            height: 40px;
            cursor: pointer;
            display: block;
            background: url('~@/assets/images/barcode.png');

            &:active, &:hover {
               background: url('~@/assets/images/barcode-hover.png');
            }
        }
    }

    .component__suggestions-label {
        position: absolute;
        z-index: 2;
        top: 59px;
        left: 0;
        display: block;
        visibility: hidden;
        overflow: auto;
        width: 100%;
        max-height: 200px;
        padding: 10px 0;
        transition: visibility .2s linear;
        border: 1px solid $search-input__suggestion-label__border-color;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: $search-input__suggestion-label__background;
    }

    .component__suggestions-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 15px;
        gap: 10px;

        &:hover, &.focused {
            background: $search-input__suggestion-item__background_hover;
        }
    }

    .suggestions-item__name,
    .suggestions-item__field {
        font-size: 0.75rem;
        font-weight: 400;
    }

    .suggestions-item__field {
        text-align: right;
        color: $search-input__suggestion-item__field__text-color;
    }

    .component__suggestions-label-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 178px;
    }

    .suggestions-label-loader__icon {
        width: 30px;
    }
</style>
