<template>
    <div class="complaint-view">
        <vue-breadcrumbs :items="[{ label: 'Подача обращения' }]"/>
        <h2 class="complaint-view__title">
            Подача обращения о нарушении предельной отпускной
            цены на лекарственный препарат
        </h2>
        <a v-bind:href="linkATO"
           target="_blank" rel="nofollow noopener"
           class="complaint-view__link">
           Также вы можете подать жалобу через форму обращения
           на официальном сайте департамента
        </a>
        <h3
            :class="{
                'error': isFormErrorsShowed && formErrorsExists,
            }"
            class="complaint-view__description"
        >Загрузите фото чека или заполните форму</h3>
        <form class="complaint-view__form">
            <div class="form__field">
                <label
                    class="field__label field__file-label"
                    for="field__file"
                >
                    <template v-if="fileName">
                        {{ fileName }}
                    </template>
                    <template v-else>
                        ЗАГРУЗИТЬ КАССОВЫЙ ЧЕК (ПРИ НАЛИЧИИ)
                    </template>
                </label>
                <input
                    @change="onFileSelect"
                    accept=".png, .jpg, .jpeg"
                    class="field__input field__file"
                    id="field__file"
                    type="file"
                />
            </div>
            <div class="form__field">
                <label
                    :class="{
                        'error': isFormErrorsShowed && formErrors.pharmacy_name === true,
                        'success': formErrors.pharmacy_name === false,
                    }"
                    class="field__label field__pharmacy-name-label"
                    for="field__pharmacy-name"
                >
                    Название аптеки*
                </label>
                <input
                    :class="{
                        'error': isFormErrorsShowed && formErrors.pharmacy_name === true,
                        'success': formErrors.pharmacy_name === false,
                    }"
                    autofocus
                    class="field__input field__pharmacy-name"
                    id="field__pharmacy-name"
                    type="text"
                    v-model="form.pharmacy_name"
                />
            </div>
            <div class="form__field">
                <label
                    :class="{
                        'error': isFormErrorsShowed && formErrors.pharmacy_address === true,
                        'success': formErrors.pharmacy_address === false,
                    }"
                    class="field__label field__pharmacy-address-label"
                    for="field__pharmacy-address"
                >
                    Адрес аптеки*
                </label>
                <div class="field__input-wrapper">
                    <ul
                        :class="{
                            'active': isSuggestionsShowed,
                        }"
                        class="field__pharmacy-address-suggestions"
                        v-if="Boolean(suggestions.length)"
                    >
                        <li
                            :key="'pharmacyAddressSuggestion_' + index"
                            @click="onAddressSelect(suggestion.value)"
                            class="pharmacy-address-suggestions__suggestion"
                            v-for="(suggestion, index) in suggestions"
                            v-html="getSuggestionText(suggestion.value)"
                        ></li>
                    </ul>
                    <input
                        :class="{
                            'error': isFormErrorsShowed && formErrors.pharmacy_address === true,
                            'success': formErrors.pharmacy_address === false,
                        }"
                        @blur="onAddressBlur"
                        @focus="onAddressFocus"
                        @input="onAddressInput"
                        class="field__input field__pharmacy-address"
                        id="field__pharmacy-address"
                        type="text"
                        v-model="form.pharmacy_address"
                    />
                </div>
            </div>
            <div class="form__field">
                <label
                    :class="{
                        'error': isFormErrorsShowed && formErrors.torgovoe_naimenovanie === true,
                        'success': formErrors.torgovoe_naimenovanie === false,
                    }"
                    class="field__label field__trade-name-label"
                    for="field__trade-name"
                >
                    Торговое наименование*
                </label>
                <input
                    :class="{
                        'error': isFormErrorsShowed && formErrors.torgovoe_naimenovanie === true,
                        'success': formErrors.torgovoe_naimenovanie === false,
                    }"
                    class="field__input field__trade-name"
                    id="field__trade-name"
                    type="text"
                    v-model="form.torgovoe_naimenovanie"
                />
            </div>
            <div class="form__field">
                <label
                    :class="{
                        'error': isFormErrorsShowed && formErrors.manufacturer === true,
                        'success': formErrors.manufacturer === false,
                    }"
                    class="field__label field__manufacturer-label"
                    for="field__manufacturer"
                >
                    Производитель*
                </label>
                <input
                    :class="{
                        'error': isFormErrorsShowed && formErrors.manufacturer === true,
                        'success': formErrors.manufacturer === false,
                    }"
                    class="field__input field__manufacturer"
                    id="field__manufacturer"
                    type="text"
                    v-model="form.manufacturer"
                />
            </div>
            <div class="form__field">
                <label
                    :class="{
                        'error': isFormErrorsShowed && formErrors.price === true,
                        'success': formErrors.price === false,
                    }"
                    class="field__label field__pharmacy-selling-price-label"
                    for="field__pharmacy-selling-price"
                >
                    Цена продажи в аптеке, руб*
                </label>
                <input
                    :class="{
                        'error': isFormErrorsShowed && formErrors.price === true,
                        'success': formErrors.price === false,
                    }"
                    class="field__input field__pharmacy-selling-price"
                    id="field__pharmacy-selling-price"
                    placeholder="100"
                    type="text"
                    v-model="form.price"
                />
            </div>
            <div class="form__field_hided">
                <input
                    type="text"
                    v-model="form.forma_vupyska"
                />
            </div>
            <div class="form__field_hided">
                <input
                    type="text"
                    v-model="form.kolichestvo_v_upakovke"
                />
            </div>
            <div class="form__field_hided">
                <input
                    type="text"
                    v-model="form.mnn"
                />
            </div>
            <p class="footnote">* Поля обязательные для заполнения</p>
            <div class="recaptcha-wrapper">
                <p class="recaptcha-error" v-if="isFormErrorsShowed && formErrors.recaptcha">
                    {{ formErrors.recaptcha }}
                </p>
                <div id="recaptcha"></div>
            </div>
            <div class="form__button-group">
                <vue-button
                    :transparent-background="true"
                    @click="clearForm"
                    class="button-group__button button-group__button-cancel"
                    text="ОТМЕНА"
                />
                <vue-button
                    @click="submitForm"
                    class="button-group__button button-group__button-send"
                    text="ОТПРАВИТЬ"
                />
            </div>
        </form>
        <vue-modal
            @close="closeModal"
            v-if="isModalShowed"
        >
            <p class="complaint-view__modal-text">
                <template v-if="modalError">
                    Ой!
                    <br/>
                    Произошла ошибка. Пожалуйста, попробуйте позже.
                </template>
                <template v-else>
                    Спасибо за обращение!
                </template>
            </p>
        </vue-modal>
    </div>
</template>

<script>
    import {computed, onMounted, ref, watch} from 'vue';
    import ButtonVue from '@/components/base/Button.vue';
    import ModalVue from '@/components/Modal.vue';
    import debounce from '@/utils/debounce';
    import {useStore} from 'vuex';
    import {onBeforeRouteUpdate, useRoute, useRouter} from 'vue-router'
    import BreadcrumbsVue from '@/components/Breadcrumbs';
    import { linkATO } from '@/services/links';

    export default {
        components: {
            'vue-button': ButtonVue,
            'vue-modal': ModalVue,
            'vue-breadcrumbs': BreadcrumbsVue,
        },
        setup() {
            const store               = useStore();
            const route               = useRoute();
            const router              = useRouter();
            const isSuggestionsShowed = ref(false);
            const isFormErrorsShowed  = ref(false);
            const formErrorsExists    = ref(false);
            const fileName            = ref('');
            const isModalShowed       = ref(false);
            const modalError          = ref(false);

            const grecaptcha = computed(() => store.getters['recaptcha/GRECAPTCHA']);

            const createWidget = () => {
                const widgetId = grecaptcha.value.render('recaptcha', {
                    'sitekey': store.getters['recaptcha/SITE_KEY'],
                    'expired-callback': () => {
                        grecaptcha.value.reset(widgetId);
                    },
                    'error-callback': () => {

                    },
                });
            };

            watch(grecaptcha, createWidget);


            let checkFile = null;

            const form = ref({
                pharmacy_name: '',
                pharmacy_address: '',
                torgovoe_naimenovanie: '',
                manufacturer: '',
                price: '',
                file_id: '',
                forma_vupyska: '',
                kolichestvo_v_upakovke: '',
                mnn: '',
            });

            const formErrors = ref({
                pharmacy_name: false,
                pharmacy_address: false,
                torgovoe_naimenovanie: false,
                manufacturer: false,
                price: false,
                file_id: false,
                recaptcha: false,
                forma_vupyska: false,
                kolichestvo_v_upakovke: false,
                mnn: false,
            });

            const suggestions = computed(() => store.getters['address/suggestions']);

            const onAddressInput = debounce(() => {
                store.dispatch('address/getSuggestions', form.value.pharmacy_address);
            });

            const onAddressFocus = () => {
                isSuggestionsShowed.value = true;
            };

            const onAddressBlur = () => {
                setTimeout(() => isSuggestionsShowed.value = false, 200);
            };

            const onAddressSelect = (payload) => {
                form.value.pharmacy_address = payload;
            };

            const getSuggestionText = (suggestionText) => {
                const addressParts = form.value.pharmacy_address.split(' ').filter(item => Boolean(item)).map(item => item.toLowerCase());

                let result = suggestionText;

                for (let addressPart of addressParts) {
                    const partLength     = addressPart.length;
                    const partStartIndex = result.toLowerCase().indexOf(addressPart);
                    const partEndIndex   = partStartIndex + partLength - 1;

                    if (partStartIndex !== -1) {
                        let originalPart = result.slice(partStartIndex, partEndIndex + 1);

                        result = result.split(originalPart).join(`<b style="font-weight: 500;">${originalPart}</b>`)
                    }
                }

                return result;
            };

            const fillFormFromQuery = (query = route.query) => {
                form.value.torgovoe_naimenovanie  = query.torgovoe_naimenovanie || '';
                form.value.manufacturer           = query.vladelec || '';
                form.value.forma_vupyska          = query.forma_vupyska || '';
                form.value.kolichestvo_v_upakovke = query.kolichestvo_v_upakovke || '';
                form.value.mnn                    = query.mnn || '';
            };

            onMounted(() => {
                store.dispatch('complaint/getToken');

                fillFormFromQuery();

                if (grecaptcha.value) {
                    createWidget();
                }
            });
            onBeforeRouteUpdate((to) => {
                fillFormFromQuery(to.query)
            });

            const submitForm = async () => {
                isFormErrorsShowed.value = false;
                if (!validateForm()) {
                    isFormErrorsShowed.value = true;
                    return;
                }

                const data = {
                    data: {
                        ...form.value,
                        recaptchaToken: grecaptcha.value.getResponse(),
                    },
                    formData: null,
                };

                if (checkFile) {
                    data.formData = new FormData();
                    data.formData.append('file', checkFile);
                }

                const {error} = await store.dispatch('complaint/sendComplaint', data);

                isModalShowed.value = true;
                modalError.value    = error;

                setTimeout(() => {
                    isModalShowed.value = false;
                }, 3000);

                setTimeout(() => {
                    isModalShowed.value = true;
                    router.push({
                        name: 'SearchView',
                    });
                }, 3000);
            };

            const validateForm = () => {
                let result = true;
                if (checkFile) {
                    formErrors.value.pharmacy_name = false;
                    formErrors.value.pharmacy_address = false;
                    formErrors.value.price = false;
                }
                else {
                    result = !(formErrors.value.pharmacy_name = (form.value.pharmacy_name.length <= 0) ? true : false);
                    result = !(formErrors.value.pharmacy_address = (form.value.pharmacy_address.length <= 0) ? true : false);
                    result = !(formErrors.value.torgovoe_naimenovanie = (form.value.torgovoe_naimenovanie.length <= 0) ? true : false);
                    result = !(formErrors.value.manufacturer = (form.value.manufacturer.length <= 0) ? true : false);
                    result = !(formErrors.value.price = ((form.value.price.length <= 0 || isNaN(parseFloat(form.value.price))) ? true : false));

                    if (form.value.pharmacy_name.length <= 0) {
                        document.getElementById("field__pharmacy-name").focus();
                    }
                }

                result = !(formErrors.value.recaptcha = ((grecaptcha.value && !grecaptcha.value.getResponse()) ? 'Пожалуйста, заполните капчу' : false));

                formErrorsExists.value = !result;

                return result;
            };

            const clearForm = () => {
                fileName.value = '';
                checkFile      = null;

                router.push({
                    name: 'SearchView',
                });
            };

            const onFileSelect = (event) => {
                if (event.target.files[0]) {
                    fileName.value = event.target.files[0].name;
                    checkFile      = event.target.files[0];
                }
            };

            const closeModal = () => {
                isModalShowed.value = false;

                if (!modalError.value) {
                    router.push({
                        name: 'SearchView',
                    });
                }
            };

            return {
                form,
                formErrors,
                onAddressInput,
                onAddressFocus,
                onAddressBlur,
                onAddressSelect,
                suggestions,
                isSuggestionsShowed,
                getSuggestionText,
                clearForm,
                submitForm,
                isFormErrorsShowed,
                formErrorsExists,
                onFileSelect,
                fileName,
                isModalShowed,
                modalError,
                closeModal,
                linkATO,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .complaint-view {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px 15px 15px;

        @media screen and (min-width: $breakpoint_tablet) {
            padding: 10px 30px 15px;
        }
    }

    .complaint-view__modal-text {
        font-size: 1rem;
        font-weight: 500;
        padding: 5px 25px 20px;
        text-align: center;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.25rem;
            padding: 20px 50px 35px;
        }
    }

    .complaint-view__breadcrumbs {
        font-size: 0.75rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 5px;
        gap: 4px;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.25rem;
            margin-bottom: 10px;
            gap: 6px;
        }
    }

    .breadcrumbs__element {
        &.active {
            font-weight: 700;
        }
    }

    .breadcrumbs__element_link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .complaint-view__title {
        font-size: 0.875rem;
        font-weight: 700;
        max-width: 800px;
        margin-bottom: 10px;
        text-align: center;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.75rem;
            margin-bottom: 15px;
        }
    }

    .complaint-view__link {
        font-size: 1.1rem;
        font-weight: 700;
        max-width: 800px;
        margin-bottom: 15px;
        text-align: center;

        @media screen and (max-width: $breakpoint_mobile) {
            font-size: 0.675rem;
            margin-bottom: 10px;
        }

        @media screen and (max-width: $breakpoint_tablet_navbar) and (min-width: $breakpoint_mobile) {
            font-size: 1rem;
            margin-bottom: 10px;
        }
    }

    .complaint-view__description {
        font-size: 0.75rem;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.25rem;
            margin-bottom: 30px;
        }

        &.error {
            color: $error__text-color;
        }
    }

    .complaint-view__form {
        display: flex;
        flex-wrap: wrap;
        max-width: 660px;
        gap: 8px;

        @media screen and (min-width: $breakpoint_tablet) {
            gap: 20px;
        }
    }

    .form__field {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;

        @media screen and (min-width: $breakpoint_tablet) {
            gap: 16px;
        }
    }

    .form__field_hided {
        display: none;
    }

    .field__label, .field__input {
        flex-basis: 100%;
    }

    .field__label {
        font-size: 0.75rem;
        font-weight: 500;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.125rem;
            display: flex;
            align-items: center;
            flex-basis: 24%;
        }

        &.error {
            color: $error__text-color;
        }
    }

    .field__input-wrapper {
        position: relative;
        flex: 1 0 0;

        .field__input {
            width: 100%;
        }
    }

    .field__input {
        font-size: 1rem;
        font-weight: 700;
        height: 45px;
        padding: 13px;
        color: $input__text-color;
        border: 1px solid $input__border-color;
        border-radius: 10px;
        outline: none;
        background: $input__background;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.25rem;
            flex: 1 0 0;
        }

        &.error {
            border-color: $error__text-color;
        }

        &.success {
            border-color: $input__border-color_sucess;
            background: $input__background;
        }
    }

    .field__file-label {
        font-size: 0.625rem;
        font-weight: 700;
        cursor: pointer;
        text-align: center;
        text-decoration: underline;
        color: $input-file__text-color;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.125rem;
            display: block;
            flex-basis: 100%;
            margin-bottom: 10px;
        }
    }

    .field__file {
        display: none;
    }

    .field__pharmacy-address-suggestions {
        position: absolute;
        top: 44px;
        left: 0;
        display: none;
        width: 100%;
        padding: 10px 0;
        border: 1px solid $input__border-color;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        background: $complaint-form__address-suggestion-background;

        &.active {
            display: block;
        }

        &.active + .field__input {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .field__pharmacy-selling-price {
        &::placeholder {
            color: $input__text-color_placeholder;
        }
    }

    .pharmacy-address-suggestions__suggestion {
        font-size: 16px;
        font-weight: 400;
        padding: 10px 15px;
        cursor: pointer;

        &:hover {
            background: $complaint-form__address-suggestion-background_hover;
        }
    }

    .footnote {
        font-size: 0.75rem;
        font-weight: 400;
        margin-bottom: 10px;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.25rem;
            margin-top: 10px;
        }
    }

    .form__button-group {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 15px;

        @media screen and (min-width: $breakpoint_desktop) {
            margin-left: calc(24% + 16px);
        }
    }

    .button-group__button {
        @media screen and (min-width: $breakpoint_tablet) {
            flex-basis: calc(50% - 10px);
        }
    }

    .button-group__button-cancel {
        order: 2;

        @media screen and (min-width: $breakpoint_tablet) {
            order: 1;
        }
    }

    .button-group__button-send {
        order: 1;

        @media screen and (min-width: $breakpoint_tablet) {
            order: 2;
        }
    }

    .recaptcha-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        @media screen and (min-width: $breakpoint_desktop) {
            flex-direction: row;
            margin-left: calc(24% + 16px);
        }
    }

    .recaptcha-error {
        font-size: 1.125rem;
        font-weight: 500;
        color: $error__text-color;
    }

    #recaptcha {
        margin-left: auto;
    }
</style>
