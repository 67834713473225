<template>
    <div class="how-it-works-component">
        <p>
            Государственное регулирование цен на лекарственные препараты, включенных в перечень жизненно необходимых и
            важнейших (далее перечень ЖНВЛП) осуществляется в целях повышения их доступности, как для населения, так и
            для лечебно-профилактических учреждений (поликлиники, больницы, ФАП и др.)
        </p>
        <p>
            Перечень ЖНВЛП, представляет собой таблицу, в которой выделены разделы по системам организма человека
            (сердечно-сосудистая система, пищеварительная и др.). В каждом разделе указаны лекарственные препараты для
            лечения заболеваний конкретной системы человека. Ежегодно Правительство Российской Федерации утверждает
            перечень ЖНВЛП и при необходимости вносит в него дополнения и изменения. Почему важно, что препарат внесен в
            перечень ЖНВЛП? Это влияет на предельную розничную цену лекарственного препарата, так как цена на
            медикаменты из перечня ЖНВЛП регулируется государством. На сегодняшний день в перечень ЖНВЛП входит 788
            наименований. С учетом формы выпуска, дозировок и производителей лекарственных препаратов список ЖНВЛП
            составляет более 42,8 тысяч позиций.
        </p>
        <p>
            Данный сервис предназначен для проверки предельных розничных цен на лекарственные препараты, включенные в
            перечень ЖНВЛП и позволяет проверить «правильность» цены покупаемого лекарства.
        </p>
        <p>
            В случае выявленного факта завышенной цены на лекарственный препарат пользователь сервиса имеет возможность
            сообщить о нарушении в Департамент лицензирования и регионального государственного контроля Томской области.
        </p>
        <p>
            На территории Томской области Департаментом тарифного регулирования утверждены торговые надбавки. При
            установлении размера торговой надбавки учитываются особенности Томской области, поэтому для районов,
            приравненных к Крайнему Северу (Александровский район, г. Стрежевой, г. Колпашево, г. Кедровый, Колпашевский
            район, Каргасокский район, Парабельский район, Бакчарский район, Чаинский район, Кривошеинский район,
            Молчановский район, Верхнекетский район, Тегульдетский район) устанавливается более высокие торговые
            надбавки, чем в других муниципальных образованиях (г. Томск, Кожевниковский район, Шегарский район,
            Асиновский район, Зырянский район, Томский район).
        </p>
        <p>
            Поэтому при поиске предельной розничной цены лекарственного препарата необходимо выбрать тот район, в
            котором приобретено лекарство.
        </p>
        <!--<img alt="" src="@/assets/images/how-it-works-1.png">-->
        <picture>
            <source media="(max-width: 768px)" srcset="@/assets/images/how-it-works-1-mobile.jpg">
            <source media="(min-width: $breakpoint_tablet)" srcset="@/assets/images/how-it-works-1.png">
        <img src="@/assets/images/how-it-works-1.png" alt="">
        </picture>
        <p>
            Для эффективного поиска лекарственного препарата необходимо в строку поиска ввести цифры под штрих-кодом
            лекарственного препарата.
        </p>
        <img alt="" src="@/assets/images/how-it-works-2.jpg">
        <p>
            В строке поиск введите цифры под штрих-кодом лекарственного препарата.
        </p>
        <!--<img alt="" src="@/assets/images/how-it-works-3.png">-->
        <picture>
            <source media="(max-width: 768px)" srcset="@/assets/images/how-it-works-3-mobile.jpg">
            <source media="(min-width: $breakpoint_tablet)" srcset="@/assets/images/how-it-works-3.png">
        <img src="@/assets/images/how-it-works-3.png" alt="">
        </picture>
        <p>
            Для сканирования штрих-кодов лекарственного препарата при помощи мобильных устройств на базе Android и IOS,
            для автоматического проставления в форму поиска web-сервиса (aptekacontrol.gov70.ru) цифирного значения
            штрих-кодов и последующего поиска интересующего лекарственного препарата необходимо воспользоваться кнопкой
            сканера в строке поиска.
        </p>
        <p>
            Для сканирования штрих-кодов лекарственного препарата при помощи сканера штрих-кода на персональном
            компьютере, для автоматического проставления в форму поиска web-сервиса (aptekacontrol.gov70.ru) цифирного
            значения штрих-кодов и последующего поиска интересующего лекарственного препарата необходимо установить
            курсор в строку поиска, а затем отсканировать штрих-код с помощью сканера штрих-кода.
        </p>
        <p>
            В случае если выпадает несколько позиций данного лекарственного препарата с разными ценами, то нужно
            ориентироваться на дату производства данного препарата.
        </p>
        <p>
            Дата производства лекарственного препарата указывается на упаковке, либо может содержаться в номере серии
            лекарственного препарата. Последние четыре цифры в номере обозначают месяц и год изготовления лекарственного
            препарата.
        </p>
        <img alt="" src="@/assets/images/how-it-works-4.jpg">
        <img alt="" src="@/assets/images/how-it-works-5.jpg">
        <p>
            Производители лекарственных препаратов имеют право, ежегодно осуществляет регистрацию, перерегистрацию
            отпускной цены на лекарственный препарат, которая подтверждается выпиской из приказа Министерства
            здравоохранения РФ о государственной регистрации предельной отпускной цены.
        </p>
        <p>
            Таким образом, для того, чтобы установить завышена цена или нет, необходимо обратить внимание на столбец
            «Дата вступления в силу приказа». Указанная в нём дата (месяц, год) должна соответствовать дате изготовления
            лекарственного препарата.
        </p>
        <!--<img alt="" src="@/assets/images/how-it-works-6.png">-->
        <picture>
            <source media="(max-width: 768px)" srcset="@/assets/images/how-it-works-6-mobile.jpg">
            <source media="(min-width: $breakpoint_tablet)" srcset="@/assets/images/how-it-works-6.png">
        <img src="@/assets/images/how-it-works-6.png" alt="">
        </picture>
        <p class="how-it-works-component-p">
            В случае, если при введении цифр под штрих-кодом лекарственного препарата не появляется его наименование, то
            это означает, что данное лекарство не входит в перечень ЖНВЛП и соответственно государство не регулирует его
            цену.
        </p>
        <p class="how-it-works-title">
            ИНФОРМАЦИЯ О ЛЕКАРСТВЕННЫХ ПРЕПАРАТАХ
        </p>
        <p>
            Каждый лекарственный препарат имеет регистрационное удостоверение. Регистрационное удостоверение - это официальный документ, подтверждающий соответствие медицинского препарата заявленным техническим и фармакологическим характеристикам. Все зарегистрированные лекарственные препараты внесены в Государственный реестр лекарственных средств (Госреестр). Данный сервис предоставляет возможность перейти  в Госреестр для детального ознакомления с информацией по лекарственным препаратам, входящим в перечень ЖНВЛП.
        </p>
        <picture>
            <source media="(max-width: 768px)" srcset="@/assets/images/how-it-works-7-mobile.jpg">
            <source media="(min-width: $breakpoint_tablet)" srcset="@/assets/images/how-it-works-7.png">
        <img src="@/assets/images/how-it-works-7.png" alt="">
        </picture>
        <p>
            При выборе номера регистрационного удостоверения,  осуществляется переход в  Госреестр.
        </p>
        <img alt="" src="@/assets/images/how-it-works-8.png">
        <p>
            Далее необходимо перейти в карточку регистрационного удостоверения, для этого  выбрать любой препарат из списка. В карточке регистрационного удостоверения можно ознакомиться с полной инструкцией по применению лекарственного препарта.
        </p>
        <img alt="" src="@/assets/images/how-it-works-9.png">
    </div>
</template>

<script>
    export default {}
</script>

<style lang="scss">
    .how-it-works-component {
        p {
            font-size: 1.1rem;
            line-height: 1.3;
            margin: 15px 0;

            @media screen and (min-width: $breakpoint_desktop) {
                text-indent: 40px;
            }
        }

        img {
            display: block;
            max-width: 100%;
            margin: 15px auto;
        }
    }

    .how-it-works-component-p {
        font-weight: 700;
    }

    .how-it-works-title {
        text-align: center;
    }
</style>
