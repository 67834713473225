/**
 * Loads external js file by path
 * @param {string} scriptSrc
 * @return {Promise}
 */
export default function loadScript(scriptSrc) {
    return new Promise(function (resolve, reject) {
        const script   = document.createElement('script');
        script.async   = true;
        script.src     = scriptSrc;
        script.onload  = resolve;
        script.onerror = (err) => reject(err);
        document.head.append(script);
    });
}
