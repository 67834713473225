<template>
    <ul
        class="breadcrumbs-component"
        v-if="items"
    >
        <li class="breadcrumbs__item">
            <router-link
                :to="{ name: 'SearchView' }"
                class="breadcrumbs__item-link"
            >Главная
            </router-link>
        </li>
        <template
            :key="index"
            v-for="(item, index) in items"
        >
            <li class="breadcrumbs__item">/</li>
            <li
                class="breadcrumbs__item active"
                v-if="index === items.length - 1"
            >{{ item.label }}
            </li>
            <li
                class="breadcrumbs__item"
                v-else
            >
                <router-link
                    :to="item.to"
                    class="breadcrumbs__item"
                >{{ item.label }}
                </router-link>
            </li>
        </template>
    </ul>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                default: null,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .breadcrumbs-component {
        font-size: 0.75rem;
        margin-bottom: 10px;
        margin-left: auto;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.25rem;
            margin-bottom: 30px;
        }
    }

    .breadcrumbs__item {
        display: inline;

        &:not(:last-child) {
            margin-right: 4px;

            @media screen and (min-width: $breakpoint_tablet) {
                margin-right: 6px;
            }
        }

        &.active {
            font-weight: 700;
        }
    }

    .breadcrumbs__item-link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
</style>
