import api from './index';

export default {
    search(params) {
        const data_reg_ceny_i_nomer_ru = "data_reg_ceny_i_nomer_ru";
        return api().get('/medicinalproducts/search', {params})
            .then(payload => {
                const columns = payload.data?.columns;
                if (Array.isArray(columns)) {
                    columns.push({
                        name: data_reg_ceny_i_nomer_ru,
                        value: "Номер РУ"
                    });
                }

                if (payload.data?.search_results?.data && Array.isArray(payload.data?.search_results?.data)) {
                    const results = payload.data?.search_results?.data;
                    results.forEach(row => {
                        let dataPrikaza               = row['data_prikaza'] ? (row['data_prikaza']).split('-', 3) : '';
                        dataPrikaza                   = `${dataPrikaza[2]}.${dataPrikaza[1]}.${dataPrikaza[0]}`;
                        row[data_reg_ceny_i_nomer_ru] = `${dataPrikaza} ${row['nomer_ru']}`;
                        if (row['data_vstuplenia_w_sily']) {
                            let date = (row['data_vstuplenia_w_sily']).split('-',3);
                            row['data_vstuplenia_w_sily'] = `${date[2]}.${date[1]}.${date[0]}`;
                        }
                    });
                }
                return payload;
            });

    },
    suggest(params) {
        return api().get('/medicinalproducts/suggest', {params});
    },
};
