<template>
    <header class="the-header-component">
        <router-link
            :to="{ name: 'SearchView' }"
            class="header__logo"
        >
            <img
                alt="Логотип"
                class="header__logo-image"
                src="@/assets/images/logo.png"
            />
            <div class="header__logo-description">
                <h3 class="logo-description__title">
                    Администрация Томской области
                </h3>
                <p class="logo-description__text">
                    Департамент лицензирования и регионального
                    <br/>
                    государственного контроля Томской области
                </p>
            </div>
        </router-link>
        <vue-navbar/>
    </header>
</template>

<script>
    import Navbar from "@/components/Navbar.vue";

    export default {
        components: {
            'vue-navbar': Navbar,
        },
    };
</script>

<style lang="scss" scoped>
    .the-header-component {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%;
        height: $header__height_mobile;
        padding: 0 15px;
        background: $header__background_mobile;
        gap: 8px;

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            flex-direction: row;
            justify-content: space-between;
            height: $header__height_tablet;
            padding: 0 50px;
            background: $header__background_tablet;
        }
    }

    .header__logo {
        display: flex;
        height: 42px;
        cursor: pointer;
        text-decoration: none;
        gap: 3px;

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            height: 62px;
            gap: 15px;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            height: 83px;
        }
    }

    .header__logo-image {
        max-height: 100%;
    }

    .header__logo-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logo-description__title {
        font-size: 0.8125rem;

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            font-size: 1rem;
            color: inherit;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            font-size: 1.5rem;
        }
    }

    .logo-description__text {
        font-size: 0.5625rem;

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            font-size: 0.75rem;
            color: inherit;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            font-size: 1rem;
        }
    }

    .logo-description__title,
    .logo-description__text {
        font-weight: 500;
        color: $header__logo__text-color;

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            color: inherit;
        }
    }
</style>
