<template>
    <main class="the-main-layout">
        <slot/>
    </main>
</template>

<style lang="scss" scoped>
    .the-main-layout {
        position: relative;
    }
</style>
