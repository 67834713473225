<template>
    <div class="info-for-farm-institution-component">
        <p>
            Согласно ч. 3 ст. 63 ФЗ № 61-ФЗ «Об обращении лекарственных средств» в аптечных организациях должны быть размещены в свободном доступе и своевременно обновлены сведения о зарегистрированных предельных отпускных ценах производителей на лекарственные препараты, включенные в перечень жизненно необходимых и важнейших лекарственных препаратов.
        </p>
        <p>
            Сведения можно разместить в электронном виде с помощью персонального компьютера, планшета или терминала. Для этого необходимо открыть в браузере страницу, на которой размещен Web – сервис проверки цен. Любой посетитель аптечной организации, самостоятельно или с помощью сотрудника аптечной организации может путем сканирования штрих-кода или ввода его с помощью клавиатуры найти информацию о предельной отпускной цене лекарственного препарата из перечня жизненно необходимых и важнейших лекарственных препаратов (Перечень ЖНВЛП).
        </p>
    </div>
</template>

<script>
    export default {}
</script>

<style lang="scss">
    .info-for-farm-institution-component {
        p {
            font-size: 1.1rem;
            line-height: 1.3;
            margin: 15px 0;

            @media screen and (min-width: $breakpoint_desktop) {
                text-indent: 40px;
            }
        }
    }
</style>
