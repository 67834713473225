import {createStore} from 'vuex'
import medicines from './modules/medicines';
import address from './modules/address';
import complaint from './modules/complaint';
import accessibility from './modules/accessibility';
import recaptcha from './modules/recaptcha';
import metadataService from '@/services/metadata';

import MENU from './mod/MENU';

import {ref} from 'vue';

export default createStore({
    state: {
        districts: ref([]),
        namesMap: ref([]),
        loadingQueue: ref([]),
        codeurl: ref([]),
        footerDescription: ref([]),
        registryDescription: ref([]),
    },
    actions: {
        async REQUEST_METADATA({commit}) {
            try {
                const [districts, apiInfo] = await Promise.all([
                    metadataService.getDistricts(),
                    metadataService.getApiInfo(),
                ]);

                commit('SET_DISTRICTS', districts);
                const codeurl = apiInfo.codeurl?.[0]?.value;
                const registryDescription = apiInfo.registryDescription;
                const footerDescription = apiInfo.footerDescription?.[0]?.value;
                const fieldsInfo = apiInfo.fieldsInfo;
                commit('SET_NAMES_MAP', fieldsInfo);
                commit('SET_URL', codeurl);
                commit('SET_FOOTER', footerDescription);
                commit('SET_REGISTRY', registryDescription);
            } catch (error) {
                console.error(error);

                return error;
            }
        },
    },
    mutations: {
        SET_DISTRICTS(state, payload) {
            state.districts = payload;
        },
        SET_URL(state, payload) {
            state.codeUrl = payload;
        },
        SET_FOOTER(state, payload) {
            state.footerDescription = payload;
        },
        SET_REGISTRY(state, payload) {
            state.registryDescription = payload;
        },
        SET_NAMES_MAP(state, payload) {
            state.namesMap = payload;
        },
        START_LOADING(state, id = null) {
            state.loadingQueue.push(id);
        },
        STOP_LOADING(state, id = null) {
            state.loadingQueue.splice(state.loadingQueue.indexOf(id), 1);
        },
    },
    getters: {
        DISTRICTS(state) {
            return state.districts;
        },
        CODEURL(state) {
            return state.codeUrl;
        },
        FOOTER(state) {
            return state.footerDescription;
        },
        REGISTRY(state) {
            return state.registryDescription;
        },
        NAMES(state) {
            return (value) => {
                return state.namesMap.find(item => {
                    return [...Object.values(item)].includes(value);
                });
            };
        },
        LOADING_STATUS(state) {
            return state.loadingQueue.length > 0;
        },
    },
    modules: {
        medicines,
        address,
        complaint,
        recaptcha,
        MENU,
        accessibility,
    },
});
