import {createApp, defineAsyncComponent} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Loader from "@/components/Loader.vue";
import loadScript from './utils/loadScript';

const app = createApp(App);
app.use(router).use(store).mount('#app');

app.component('qr-code-reader', defineAsyncComponent({
    // The factory function
    loader: () => {
        return Promise.all([
            import('./components/QrCodeReader/QrCodeReader.vue'),
            loadScript('html5-qrcode.min.js'),
        ]).then(loadedResources => loadedResources[0]); // return first promise result - vue component only
    },
    // A component to use while the async component is loading
    loadingComponent: Loader,
    // Delay before showing the loading component. Default: 200ms.
    delay: 200,
}));
