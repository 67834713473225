import {ref} from 'vue';
import medicinesService from '@/services/medicines';

const state = {
    suggestions: [],
    searchResult: ref({}),

    suggestionsLoading: ref(false),
};

const actions = {
    async getSuggestions({commit}, payload) {
        try {
            commit('setSuggestionsLoading', true);
            commit('setSuggestions', response?.data?.data || []);

            const response = await medicinesService.suggest(payload);

            commit('setSuggestions', response?.data?.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            commit('setSuggestionsLoading', false);
        }
    },
    async search({commit}, payload) {
        try {
            commit('START_LOADING', null, {root: true});
            commit('setSearchResult', []);

            const response = await medicinesService.search(payload);

            commit('setSearchResult', response?.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            commit('STOP_LOADING', null, {root: true});
        }
    },
};

const mutations = {
    setSuggestions(state, payload) {
        state.suggestions = payload;
    },
    setSearchResult(state, payload) {
        state.searchResult = payload;
    },
    setSuggestionsLoading(state, payload) {
        state.suggestionsLoading = payload;
    },
};

const getters = {
    suggestions(state) {
        return state.suggestions;
    },
    suggestionsLoading() {
        return state.suggestionsLoading;
    },
    searchResult(state) {
        return state.searchResult;
    },
    resultColumns(state) {
        return state.searchResult?.columns || [];
    },
    resultData(state) {
        return state.searchResult?.search_results?.data || [];
    },
    resultLinks(state) {
        return state.searchResult?.search_results?.links || [];
    },
    resultNextLink(state) {
        return state.searchResult?.search_results?.next_page_url;
    },
    resultPrevLink(state) {
        return state.searchResult?.search_results?.prev_page_url;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
