<template>
    <div class="title-component">
        <slot name="prepend-icon"/>
        <h2 class="title-component__text">
            <slot/>
        </h2>
        <slot name="append-icon"/>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss">
    .title-component {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media screen and (min-width: $breakpoint_tablet) {
            gap: 20px;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            gap: 25px;
        }
    }

    .title-component__text {
        font-size: 0.875rem;
        font-weight: 700;
        text-align: center;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.5rem;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            font-size: 2.5rem;
        }
    }
</style>
