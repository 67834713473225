const nameMap = {
    mnn: 'мнн',
    torgovoe_naimenovanie: 'торговое_наименование',
    forma_vupyska: 'форма_выпуска',
    shtrihkod: 'штрихкод',
    vladelec: 'владелец',
};

export function getName(value) {
    return Object.keys(nameMap).find(key => nameMap[key] === value);
}

export function getValue(name) {
    return nameMap[name];
}
