<template>
    <div class="pagination-component">
        <ul class="pagination-component__links-list">
            <li
                @click="onClick(toStartLink)"
                class="pagination-component__link button"
                v-if="mode === 'small' && links.length"
            >
                <button
                    class="pagination-component__button left double-arrow"
                ></button>
            </li>
            <li
                :class="{
                    'active': link.active,
                    'page': Boolean(link.url) && !(index === 0 || index === filteredLinks.length - 1),
                    'button': index === 0 || index === filteredLinks.length - 1,
                }"
                :key="'link_' + index"
                @click="onClick(link, !Boolean(link.url))"
                class="pagination-component__link"
                v-for="(link, index) in filteredLinks"
            >
                <button
                    class="pagination-component__button left"
                    v-if="index === 0"
                ></button>
                <button
                    class="pagination-component__button"
                    v-else-if="index === filteredLinks.length - 1"
                ></button>
                <template v-else>
                    {{ link.label }}
                </template>
            </li>
            <li
                @click="onClick(toEndLink)"
                class="pagination-component__link button"
                v-if="mode === 'small' && links.length"
            >
                <button
                    class="pagination-component__button double-arrow"
                ></button>
            </li>
        </ul>
    </div>
</template>

<script>
    import {computed, onBeforeUnmount, onMounted, ref} from 'vue';

    import debounce from '@/utils/debounce';

    export default {
        props: {
            links: {
                type: Array,
                default: null,
            },
            maxLinksCount: {
                type: Number,
                validator: maxLinksCount => maxLinksCount > 0,
            },
        },
        emits: {
            'click': () => true,
        },
        setup(props, context) {
            const mode = ref(window.innerWidth < 768 ? 'small' : 'large');

            const onClick = (payload, prevent) => {
                if (prevent) {
                    return;
                }

                context.emit('click', payload);
            };

            const filteredLinks = computed(() => {
                let result = [];
                const active      = props.links.find(link => link.active);
                const activeIndex = props.links.indexOf(active);

                switch (mode.value) {
                    case 'large':
                        result = props.links;
                        break;
                    case 'small':

                        if (activeIndex !== -1) {
                            let links = props.links;

                            if (activeIndex === 1) {
                                if (props.links.length === 3) {
                                    result = links.slice(activeIndex - 1).slice(0, 4);
                                } else {
                                    result = links.slice(activeIndex - 1).slice(0, 4).concat(links[links.length - 1]);
                                }

                                break;
                            }

                            if (activeIndex === links.length - 2) {
                                result = [links[0]].concat(links.slice(activeIndex - 2).slice(0, 4));

                                break;
                            }

                            result = [links[0]].concat(links.slice(activeIndex - 1).slice(0, 3), links[links.length - 1]);
                        }

                        break;
                }


                return result;
            });

            const toStartLink = computed(() => {
                return props.links[1];
            });

            const toEndLink = computed(() => {
                return props.links[props.links.length - 2];
            });

            const onResize = debounce((event) => {
                if (event.currentTarget.innerWidth < 768 && mode.value !== 'small') {
                    mode.value = 'small';
                } else if (event.currentTarget.innerWidth >= 768 && mode.value !== 'large') {
                    mode.value = 'large';
                }
            });

            onMounted(() => {
                window.addEventListener('resize', onResize);
            });

            onBeforeUnmount(() => {
                window.removeEventListener('resize', onResize);
            });

            return {
                onClick,
                filteredLinks,
                mode,
                toStartLink,
                toEndLink,
            };
        },
    };
</script>

<style lang="scss">
    .pagination-component {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .pagination-component__button {
        position: relative;
        width: 50px;
        height: 50px;
        cursor: pointer;
        border: none;
        border-radius: 50%;
        background: $pagination__background;
        box-shadow: $pagination__box-shadow;

        &:after {
            position: absolute;
            top: calc(50% - 5px);
            left: calc(50% - 7px);
            display: inline-block;
            padding: 4px;
            content: "";
            transform: rotate(-45deg);
            border: solid $pagination__arrow-color;
            border-width: 0 2px 2px 0;
        }

        &.left {
            transform: rotate(180deg);
        }

        &.double-arrow {
            &:after {
                position: absolute;
                top: calc(50% - 5px);
                left: calc(50% - 2px);
                display: inline-block;
                padding: 4px;
                content: "";
                transform: rotate(-45deg);
                border: solid $pagination__arrow-color;
                border-width: 0 2px 2px 0;
            }

            &:before {
                position: absolute;
                top: calc(50% - 5px);
                left: calc(50% - 11px);
                display: inline-block;
                padding: 4px;
                content: "";
                transform: rotate(-45deg);
                border: solid $pagination__arrow-color;
                border-width: 0 2px 2px 0;
            }
        }
    }

    .pagination-component__links-list {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .pagination-component__link {
        cursor: default;

        &.page {
            font-size: 0.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            cursor: pointer;
            transition: transform .2s ease-out;
            border: 1px solid $pagination__page__border-color;
            border-radius: 50%;

            &:hover {
                transform: scale(1.2);
            }

            &.active {
                transform: scale(1.4);
            }
        }
    }
</style>
