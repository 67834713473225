<template>
    <div class="modal-component">
        <div class="modal-component__modal">
            <div class="modal-component__modal-header">
                <span
                    @click="onClose"
                    class="modal-header__close-icon"
                ></span>
            </div>
            <div class="modal-component__modal-body">
                <slot/>
            </div>
            <div class="modal-component__modal-footer"></div>
        </div>
    </div>
</template>

<script>
    export default {
        setup(props, context) {
            const onClose = () => {
                context.emit('close');
            };

            return {onClose};
        },
    };
</script>

<style lang="scss">
    .modal-component {
        position: fixed;
        z-index: 8888;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        background-color: $modal__background;
    }

    .modal-component__modal {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: min(90vw, 488px);
        border: 1px solid $modal__border-color;
        border-radius: 10px;
        background: $modal__component__background;
    }

    .modal-component__modal-header {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 15px;
    }

    .modal-header__close-icon {
        position: relative;
        width: 15px;
        height: 15px;
        margin-left: auto;
        cursor: pointer;

        &::before, &::after {
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            display: block;
            width: 20px;
            height: 2px;
            content: "";
            background: $modal__close-icon__color;
        }

        &::before {
            transform: translateX(-3px) rotateZ(45deg);
        }

        &::after {
            transform: translateX(-3px) rotateZ(-45deg);
        }
    }

    .modal-component__modal-body {
        width: 100%;
        padding: 0 15px;
    }

    .modal-component__modal-footer {
        width: 100%;
        padding: 15px;
    }
</style>
