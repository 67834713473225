<template>
    <nav class="navbar-component">
        <div
            :class="{ 'active': isMenuActive }"
            @click="toggleMenu"
            class="navbar-component__hamburger"
        >
            <span class="hamburger__icon hamburger__icon_top"></span>
            <span class="hamburger__icon hamburger__icon_center"></span>
            <span class="hamburger__icon hamburger__icon_bottom"></span>
        </div>
        <vue-menu
            :isActive="isMenuActive"
            @menuItemSelect="closeMenu"
        >
            <template #append>
                <div class="navbar-component__accessibility">
                    <img
                        @click="toggleAccessibility"
                        alt="Версия для слабовидящих"
                        class="accessibility__icon"
                        src="@/assets/images/eye.png"
                    />
                </div>
            </template>
        </vue-menu>
    </nav>
</template>

<script>
    import Menu from '@/components/Menu';
    import {computed, ref} from 'vue';
    import {useStore} from 'vuex';

    export default {
        components: {
            'vue-menu': Menu,
        },
        setup() {
            const isMenuActive = ref(false);
            const store        = useStore();

            const accessibilityEnabled = computed(() => store.getters['accessibility/ENABLED']);
            const toggleMenu           = () => isMenuActive.value = !isMenuActive.value;
            const closeMenu            = () => isMenuActive.value = false;
            const toggleAccessibility  = () => {
                if (!accessibilityEnabled.value) {
                    store.commit('accessibility/ENABLE');
                } else {
                    store.commit('accessibility/DISABLE');
                }

                closeMenu();
            };

            return {
                isMenuActive,
                toggleMenu,
                closeMenu,
                toggleAccessibility,
                accessibilityEnabled,
            };
        },
    };
</script>

<style lang="scss">
    .navbar-component__hamburger {
        position: relative;
        width: 22px;
        height: 16px;
        cursor: pointer;

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            display: none;
        }

        &.active {
            .hamburger__icon_center {
                opacity: 0;
            }

            .hamburger__icon_top {
                transform: translate(0, 7px) rotate(45deg);
            }

            .hamburger__icon_bottom {
                transform: translate(0, -7px) rotate(-45deg);
            }
        }
    }

    .hamburger__icon {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        transition: all .2s ease-out;
        background: $navbar__hamburger-icon__background;
    }

    .hamburger__icon_top {
        top: 0;
    }

    .hamburger__icon_center {
        top: calc(50% - 1px);
    }

    .hamburger__icon_bottom {
        bottom: 0;
    }

    .navbar-component__accessibility {
        height: 23px;
        margin-top: 0px;
        padding-left: 15px;

        @media screen and (min-width: $breakpoint_tablet_navbar) {
            height: 26px;
            margin: 0;
            padding-left: 60px;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            height: 32px;
        }
    }

    .accessibility__icon {
        max-height: 100%;
        cursor: pointer;
        transition: transform .2s ease-out;

        &:hover {
            transform: scale(1.15);
        }
    }
</style>
