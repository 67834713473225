import addressService from '@/services/address';

const state = {
    suggestions: [],
};

const actions = {
    async getSuggestions({commit}, payload) {
        try {
            const suggestions = await addressService.getSuggestions(payload);

            commit('setSuggestions', suggestions || []);
        } catch (error) {
            console.error(error);
        }
    },
};

const mutations = {
    setSuggestions(state, payload) {
        state.suggestions = payload;
    },
};

const getters = {
    suggestions(state) {
        return state.suggestions;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
