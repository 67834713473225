<template>
    <div class="the-footer-layout">
        <p class="footer__text" v-html="footer">
        </p>
    </div>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';

export default ({
    setup() {
            const store  = useStore();
            const footer = computed(() => store.getters['FOOTER']);
    return {
        footer,
    }
    },
})
</script>


<style lang="scss" scoped>
    .the-footer-layout {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 52px;
        padding: 0 20px;
        background: linear-gradient(180deg, #D5EAF2 0%, #C2D9E1 100%);
    }

    .footer__text {
        font-size: 0.6875rem;
        text-align: center;

        @media (min-width: $breakpoint_desktop) {
            font-size: 0.875rem;
        }
    }

    .footer__link {
        text-decoration: none;
    }
</style>
