function stringToDate(string) {
    let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    return new Date(string.replace(pattern,'$3-$2-$1'));
}

function filterByField(arr) {
    let obj={};

    arr.forEach( entry => {
        if (obj[entry.shtrihkod]){
            if (stringToDate(obj[entry.shtrihkod].data_vstuplenia_w_sily) < stringToDate(entry.data_vstuplenia_w_sily)) {
                delete obj[entry.shtrihkod];
                obj[entry.shtrihkod] = entry;
            }
        } else {
            obj[entry.shtrihkod] = entry;
        }
    });

    return Object.values(obj);
}

export default function filterData(array) {
    return filterByField([...new Map(array.map(obj => [JSON.stringify(obj), obj])).values()]);
}
