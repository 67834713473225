import AboutView from "@/views/AboutView.vue";
import ComplaintView from "@/views/ComplaintView.vue";
import SearchView from "@/views/SearchView.vue";
import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "SearchView",
        component: SearchView,
    }, {
        path: "/about",
        name: "AboutView",
        component: AboutView,
    }, {
        path: "/complaint",
        name: "ComplaintView",
        component: ComplaintView,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
