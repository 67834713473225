import api from './index';

export default {
    async getToken() {
        const response = await api().get('/medicinalproducts/send_complaint');

        return response;
    },
    async uploadComplaintCheck(formData) {
        const response = await api().post('/medicinalproducts/upload_cheques', formData);

        return response.data?.fileId;
    },
    async sendComplaint(body) {
        const response = await api().post('/medicinalproducts/send_complaint', body);

        return response;
    },
};
