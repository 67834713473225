import api from './index';

export default {
    async getSuggestions(query) {
        const response = await api().post(
            process.env.VUE_APP_ADDRESS_SUGGESTIONS_API_URL,
            {
                query,
                count: 5,
                locations: [
                    {
                        region_fias_id: '889b1f3a-98aa-40fc-9d3d-0f41192758ab',
                    }
                ],
                locations_boost: [
                    {
                        kladr_id: 7000000100000,
                    }
                ],
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Token ' + process.env.VUE_APP_ADDRESS_SUGGESTIONS_API_TOKEN,
                },
            }
        );

        return response.data?.suggestions;
    },
};
