const state = {
    items: [
        // {
        //     label: 'ГЛАВНАЯ',
        //     to: { name: 'SearchView' },
        // }, {
        //     label: 'РЕЗУЛЬТАТЫ ПРОВЕРОК',
        //     to: { name: 'ChecksView' },
        // }
        // , {
        //     label: 'О СЕРВИСЕ',
        //     to: { name: 'AboutView' },
        // }
    ],
    activeItem: null,
};

const getters = {
    ITEMS(state) {
        return state.items;
    },
};

export default {
    namespaced: true,
    state,
    getters,
};
