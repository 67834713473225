<template>
    <span class="app__background-left"></span>
    <div :class="{ 'accessibility-enabled': accessibilityEnabled }" class="app__content">
        <span class="app__content-background"></span>
        <vue-header ref="header"/>
        <vue-main ref="main">
            <router-view/>
        </vue-main>
        <vue-footer ref="footer"/>
    </div>
    <span class="app__background-right"></span>

    <vue-loader v-if="loadingStatus"/>
    <vue-loader
        :class="{
            'active': isInitialLoaderActive,
        }"
        class="initial-loader"
        text="Пожалуйста, подождите..."
    />
    <vue-modal @close="errorModalShowed = false" v-if="errorModalShowed && route.name === 'SearchView'">
        <p class="app__modal-text">
            Ошибка!
            <br/>
            <br/>
            Сервис временно недоступен. Пожалуйста, попробуйте позже.
        </p>
    </vue-modal>
    <vue-accessibility/>
</template>

<script>
    import TheHeaderVue from "@/layout/TheHeader.vue";
    import TheFooterVue from "@/layout/TheFooter.vue";
    import TheMainVue from "@/layout/TheMain.vue";
    import Loader from "@/components/Loader.vue";
    import Modal from "@/components/Modal.vue";

    import {computed, onMounted, ref} from 'vue';
    import {useStore} from 'vuex';
    import {useRoute} from 'vue-router'

    import debounce from '@/utils/debounce';
    import AccessibilityVue from './components/Accessibility.vue';

    export default {
        components: {
            'vue-header': TheHeaderVue,
            'vue-footer': TheFooterVue,
            'vue-main': TheMainVue,
            'vue-loader': Loader,
            'vue-modal': Modal,
            'vue-accessibility': AccessibilityVue,
        },
        setup() {
            const store                 = useStore();
            const route                 = useRoute();
            const isInitialLoaderActive = ref(true);
            const loadingStatus         = computed(() => store.getters['LOADING_STATUS']);
            const errorModalShowed      = ref(false);

            const accessibilityEnabled = computed(() => store.getters['accessibility/ENABLED']);

            window.vueRecaptchaApiLoaded = () => store.commit('recaptcha/SET_GRECAPTCHA', window.grecaptcha);

            const requestMetadata = async () => {
                const result = await store.dispatch('REQUEST_METADATA');

                if (result instanceof Error) {
                    errorModalShowed.value = true;
                }

                breakInitialLoader();
            };

            const breakInitialLoader = () => {
                if (loadingStatus.value) {
                    setTimeout(() => {
                        breakInitialLoader();
                    }, 500);

                    return;
                }

                isInitialLoaderActive.value = false;
            };

            requestMetadata();

            const header = ref(null);
            const footer = ref(null);
            const main   = ref(null);

            window.addEventListener('resize', debounce(() => setBodyMinHeight(), 100));
            onMounted(() => setBodyMinHeight());

            const setBodyMinHeight = () => {
                const headerHeight = header.value.$el.offsetHeight;
                const footerHeight = footer.value.$el.offsetHeight;

                main.value.$el.style.minHeight = (window.innerHeight - headerHeight - footerHeight) + 'px';
            };

            return {
                loadingStatus,
                isInitialLoaderActive,
                header,
                footer,
                main,
                errorModalShowed,
                route,
                accessibilityEnabled,
            };
        },
    };
</script>

<style lang="scss">
    #app {
        position: relative;
        display: flex;
    }

    .initial-loader {
        visibility: hidden;
        transition: opacity .5s ease-out, visibility 0s .5s;
        opacity: 0;
        background: $initial-loader__background;

        &.active {
            visibility: visible;
            opacity: 1;
        }

        .loader-component__loader-text {
            color: $initial-loader__text-color;
        }
    }

    .app__background-left, .app__background-right {
        flex: 1;

        &::after {
            display: block;
            height: 100%;
            max-height: 850px;
            content: "";
        }
    }

    .app__background-left {
        background: $app__left_background;

        &::after {
            background-image: $app__left_background-image;
        }
    }

    .app__background-right {
        background: $app__right_background;

        &::after {
            background-image: $app__right_background-image;
        }
    }

    .app__content {
        position: relative;
        width: 100%;
        max-width: $app__max-width;
        margin: 0 auto;
        background: $app__content_background;

        &.accessibility-enabled {
            margin-top: 40px;

            @media screen and (max-width: $breakpoint_tablet) {
                margin-top: 135px;
            }
        }

        .app__content-background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: $app__content_background-image;
            background-repeat: no-repeat;
            background-position: 100% 0%;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-image: $app__content_background-image-repeat;
            background-position: 100% 0%;
        }
    }

    .app__modal-text {
        font-size: 1rem;
        font-weight: 500;
        padding: 5px 25px 20px;
        text-align: center;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.25rem;
            padding: 20px 50px 35px;
        }
    }
</style>
