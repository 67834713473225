<template>
    <div class="loader-component">
        <div class="loader-component__loader-wrapper">
            <img
                alt="Загрузка"
                class="loader-component__loader-icon"
                src="@/assets/images/loading_200x200.gif"
            />
            <h3 class="loader-component__loader-text">{{ calcText }}</h3>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: null,
            },
        },
        computed: {
            calcText() {
                return this.text ? this.text : 'Идет загрузка, пожалуйста, ждите.'  ;
            }
        }
    };
</script>

<style lang="scss">
    .loader-component {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        background: $loader__background;
    }

    .loader-component__loader-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .loader-component__loader-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
    }

    .loader-component__loader-text {
        font-size: 1.25rem;
        font-weight: 700;
        flex-basis: 100%;
        color: $loader__text-color;
    }
</style>
