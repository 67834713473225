import complaintService from '@/services/complaint';

const state = {};

const actions = {
    async getToken() {
        try {
            await complaintService.getToken();
        } catch (error) {
            console.error(error);
        }
    },
    async sendComplaint({commit}, {data, formData}) {
        try {
            commit('START_LOADING', null, {root: true});

            if (formData && formData.has('file')) {
                const fileId = await complaintService.uploadComplaintCheck(formData);

                data.file_id = fileId;
            }

            await complaintService.sendComplaint(data);

            return {error: false};
        } catch (error) {
            console.error(error);

            return {error: true};
        } finally {
            commit('STOP_LOADING', null, {root: true});
        }
    },
};

const mutations = {
    setSuggestions(state, payload) {
        state.suggestions = payload;
    },
};

const getters = {
    suggestions(state) {
        return state.suggestions;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
