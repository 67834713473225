<template>
    <div class="search-view">
        <p class="search-view__description">
            {{registry?.[0]?.value}}
        </p>
        <vue-title class="search-view__title">
            <template #prepend-icon>
                <img class="search-view__title-icon" src="@/assets/images/logo2.png"/>
            </template>
            ПРОВЕРКА ЦЕНЫ ЛЕКАРСТВЕННОГО ПРЕПАРАТА
        </vue-title>
        <div class="search-view__content-wrapper">
            <vue-search-input
                :suggestions="suggestions"
                :suggestionsLoading="suggestionsLoading"
                @clear="clearSearchString"
                @advanced="onAdvancedSearchBtnClick"
                @input="onInput"
                @select="onSelect"
                @submit="onSuggestionSubmit"
                class="search-view__search-input"
                name="searchInput"
                placeholder="Введите Штрих-код (цифры под штрих-кодом)"
                ref="searchInputRef"
                v-model="searchQueryView"
            />
            <div class="search-view__district">
                <div class="search-view__district_tomsk">
                    <input
                        :value="districts?.[0]?.district_name"
                        id="radio1"
                        name="radio"
                        type="radio"
                        v-model="selectedDistrict"
                    />
                    <label
                        class="search-view__district_label"
                        for="radio1"
                    >
                        <p class="search-view__district-title">Томская область (районы не приравненные к Крайнему
                            Северу)</p>
                    </label>
                </div>
                <div class="search-view__district_subject">
                    <input
                        :value="districts?.[1]?.district_name"
                        id="radio2"
                        name="radio"
                        type="radio"
                        v-model="selectedDistrict"
                    />
                    <label
                        class="search-view__district_label"
                        for="radio2"
                    >
                        <p class="search-view__district-title">
                            Томская область (с учетом районов Крайнего Севера)
                            <span>
                                (г.Стрежевой, г. Колпашево, г. Кедровый, Колпашевский район,
                                Каргасокский район, Парабельский район, Бакчарский район,
                                Чаинский район, Кривошеинский район, Молчановский район,
                                Верхнекетский район, Тегульдетский район, Александровский
                                район)
                            </span>
                        </p>
                    </label>
                </div>
            </div>
            <vue-button
                @click="onSearch"
                class="search-view__button"
                tabindex="2"
                text="НАЙТИ"
            />
            <div class="how-it-works__container">
                <div @click="isHowThatWorksShowed = !isHowThatWorksShowed" class="how-it-works__button">
                    <img alt="" class="how-it-works__button-icon" src="@/assets/images/how-it-works-icon.png">
                    <span>КАК ЭТО РАБОТАЕТ?</span>
                </div>

                <div
                    class="how-it-works__content"
                    v-if="isHowThatWorksShowed"
                >
                    <vue-how-it-works/>
                </div>
            </div>
            <div class="info-for-farm-institution__container">
                <div @click="isInfoForFarmInstitutionShowed = !isInfoForFarmInstitutionShowed" class="info-for-farm-institution__button">
                    <span>ИНФОРМАЦИЯ ДЛЯ АПТЕК И ЛПУ</span>
                </div>
                <div
                    class="info-for-farm-institution__content"
                    v-if="isInfoForFarmInstitutionShowed"
                >
                    <vue-info-for-farm-institution/>
                </div>
            </div>
            <div class="search-view_dzto__container">
                <a v-bind:href="linkDZTO"
                    target="_blank" rel="nofollow noopener"
                    class="search-view_dzto__link">
                    Ознакомиться с Государственным реестром предельных отпускных цен на лекарственные препараты, включенные в Перечень жизненно необходимых и важнейших лекарственных препаратов (Перечень ЖНВЛП)
                </a>
            </div>
            <h3 class="additional-fields-block__title"
                v-if="gridColumns.length && resultData.length">ДОПОЛНИТЕЛЬНЫЕ ПОЛЯ:</h3>
            <div class="search-view__additional-fields-block"
                 v-if="gridColumns.length && resultData.length">
                <div class="additional-fields-block__addition-field">
                    <input id="checkbox1" type="checkbox" v-model="additionColumns" value="nomer_prikaza"/>
                    <label for="checkbox1">
                        <span class="check-box">
                            <span class="check-mark"></span>
                        </span>
                        <p>Номер приказа</p>
                    </label>
                </div>
                <div class="additional-fields-block__addition-field">
                    <input id="checkbox2" type="checkbox" v-model="additionColumns" value="optovaya_cena"/>
                    <label for="checkbox2">
                        <span class="check-box">
                            <span class="check-mark"></span>
                        </span>
                        <p>Предельная оптовая цена с НДС, руб.</p>
                    </label>
                </div>
                <div class="additional-fields-block__addition-field">
                    <input id="checkbox3" type="checkbox" v-model="additionColumns" value="shtrihkod"/>
                    <label class="additional-fields-block__addition-field_barcode" for="checkbox3">
                        <span class="check-box">
                            <span class="check-mark"></span>
                        </span>
                        <p>Штрих-код</p>
                    </label>
                </div>
                <div class="additional-fields-block__addition-field">
                    <input id="checkbox4" type="checkbox"
                    v-model="additionColumns" value="actual_positions"
                    @change="valueChanged">
                    <label class="additional-fields-block__addition-field_actual_positions" for="checkbox4">
                        <span class="check-box">
                            <span class="check-mark"></span>
                        </span>
                        <p>Показывать позиции только с актуальными зарегистрированными ценами (без истории изменений)</p>
                    </label>
                </div>
            </div>
        </div>
        <div class="search-view__sort" v-if="resultData.length">
            <label class="search-view__sort-label">СОРТИРОВАТЬ ПО:</label>
            <img
                :class="{
                    'desc': selectedSort === 'desc',
                }"
                @click="changeOrder"
                alt="Сортировать"
                class="search-view__sort-image"
                src="@/assets/images/asc.png"
            />
            <button
                @click.stop="isSortFieldListActive = !isSortFieldListActive"
                class="search-view__sort-field"
                ref="sortListButton"
            >{{ selectedSortField?.value }}
            </button>
            <ul
                :class="{ 'active': isSortFieldListActive }"
                class="search-view__sort-field-list"
                v-if="gridColumns.length"
            >
                <li
                    :class="{ 'active': selectedSortField?.value === item?.value }"
                    :key="index"
                    @click="changeSortField(item)"
                    class="search-view__sort-field-list-item"
                    v-for="(item, index) in gridColumns"
                >
                    {{ item?.value }}
                </li>
            </ul>
        </div>
        <vue-table
            :columns="gridColumns"
            :rows="filterResultData"
            @sortASC="onSortASCHandler"
            @sortDESC="onSortDESCHandler"
            class="search-view__table"
            ref="tableRef"
            v-if="isFilterResultDataVisible"
        >

            <template #append="row">
                <router-link
                    :to="{
                        name: 'ComplaintView',
                        query: {
                            torgovoe_naimenovanie: row.row.torgovoe_naimenovanie,
                            vladelec: row.row.vladelec,
                            forma_vupyska: row.row.forma_vupyska,
                            kolichestvo_v_upakovke: row.row.kolichestvo_v_upakovke,
                            mnn: row.row.mnn,
                        }
                    }"
                    class="search-view__table-row-link"
                >
                    СООБЩИТЬ О ЗАВЫШЕННОЙ ЦЕНЕ НА ЛЕКАРСТВО
                </router-link>
            </template>
        </vue-table>
        <vue-table
            :columns="gridColumns"
            :rows="resultData"
            @sortASC="onSortASCHandler"
            @sortDESC="onSortDESCHandler"
            class="search-view__table"
            ref="tableRef"
            v-if="isResultDataVisible"
        >
            <template #append="row">
                <router-link
                    :to="{
                        name: 'ComplaintView',
                        query: {
                            torgovoe_naimenovanie: row.row.torgovoe_naimenovanie,
                            vladelec: row.row.vladelec,
                            forma_vupyska: row.row.forma_vupyska,
                            kolichestvo_v_upakovke: row.row.kolichestvo_v_upakovke,
                            mnn: row.row.mnn,
                        }
                    }"
                    class="search-view__table-row-link"
                >
                    СООБЩИТЬ О ЗАВЫШЕННОЙ ЦЕНЕ НА ЛЕКАРСТВО
                </router-link>
            </template>
        </vue-table>
        <vue-pagination
            :links="resultLinks"
            :maxLinksCount="10"
            @click="changePage"
            class="search-view__pagination"
            v-if="resultData.length"
        />

        <vue-modal v-if="enabledQrCode" @close="enabledQrCode = false">
            <qr-code-reader v-if="enabledQrCode"
                            :qrbox="170"
                            :fps="20"
                            style="width: 100%;"
                            @result="onScan"></qr-code-reader>
        </vue-modal>

        <vue-modal
            @close="isNotFindModalShowed = false"
            v-if="isNotFindModalShowed"
        >
            <p class="app__modal-text">
                Искомое лекарственное средство не найдено в реестре. Проверьте, пожалуйста, правильность введенных
                значений, если препарат входит в перечень ЖНВЛП.
            </p>
        </vue-modal>
    </div>
</template>

<script>
    import {computed, onMounted, ref, watch} from 'vue';
    import {useStore} from 'vuex';
    import {onBeforeRouteUpdate, useRoute, useRouter} from 'vue-router'

    import TitleVue from '@/components/base/Title.vue';
    import ButtonVue from '@/components/base/Button.vue';
    import TableVue from '@/components/base/Table.vue';
    import PaginationVue from '@/components/base/Pagination.vue';

    import SearchInputVue from '@/components/SearchInput.vue';

    import debounce from '@/utils/debounce';
    import {getValue} from '@/utils/nameMap';
    import filterData from '@/utils/filterData';

    import Modal from "@/components/Modal.vue";
    import HowItWorksVue from "@/components/HowItWorks.vue";
    import { linkDZTO } from '@/services/links';
import InfoForFarmInstitutionVue from '../components/InfoForFarmInstitution.vue';

    export default {
        components: {
            'vue-title': TitleVue,
            'vue-search-input': SearchInputVue,
            'vue-button': ButtonVue,
            'vue-table': TableVue,
            'vue-pagination': PaginationVue,
            'vue-modal': Modal,
            'vue-how-it-works': HowItWorksVue,
            'vue-info-for-farm-institution': InfoForFarmInstitutionVue,
        },
        setup() {
            const store  = useStore();
            const route  = useRoute();
            const router = useRouter();

            const searchString          = ref('');
            const additionColumns       = ref([]);
            const searchInputRef        = ref(null);
            const tableRef              = ref(null);
            const showFilterResultData  = ref(false);
            const sortListButton                       = ref(null);
            const suggestionsLoading                   = store.getters['medicines/suggestionsLoading'];
            const searchResult                         = computed(() => store.getters['medicines/searchResult']);
            const suggestions                          = computed(() => store.getters['medicines/suggestions']);
            const resultColumns                        = computed(() => store.getters['medicines/resultColumns']);
            const resultData                           = computed(() => store.getters['medicines/resultData']);
            const filterResultData                     = computed(() => filterData(resultData.value));

            const resultLinks                          = computed(() => store.getters['medicines/resultLinks']);
            const districts                            = computed(() => store.getters['DISTRICTS']);
            const registry                             = computed(() => store.getters['REGISTRY']);
            const selectedDistrict                     = ref(districts.value?.[0]?.district_name || '');
            const selectedSort                         = computed({
                get: () => route.query.sort || 'desc',
                set: (payload) => {
                    router.push({
                        name: 'SearchView',
                        query: {
                            ...route.query,
                            sort: payload,
                        }
                    });
                },
            });
            const selectedSortField                    = computed(() => ({
                value: store.getters['NAMES'](route.query.order)?.fieldNameTranslation || 'Дата вступления в силу приказа' || 'По умолчанию',
                name: route.query.order || 'data_vstuplenia_w_sily',
            }));
            const SCREEN_TABLET_BREAKPOINT_WIDTH       = 1165;
            const currentScreenWidth                   = ref(window.screen.width);
            const
                DESKTOP_COLS                           = [
                    'mnn',
                    'torgovoe_naimenovanie',
                    'forma_vupyska',
                    'kolichestvo_v_upakovke',
                    'vladelec',
                    'roznichnaya_cena',
                    'data_vstuplenia_w_sily',
                    'data_reg_ceny_i_nomer_ru'
                ],
                MOBILE_COLS                            = [
                    'roznichnaya_cena',
                    'data_vstuplenia_w_sily',
                    'mnn',
                    'torgovoe_naimenovanie',
                    'forma_vupyska',
                    'kolichestvo_v_upakovke',
                    'vladelec',
                    'data_reg_ceny_i_nomer_ru'
                ];
            const updateColsOrderInCaseOfMobileDesktop = () => sowCols.value = isMobileScreenSize.value ? MOBILE_COLS : DESKTOP_COLS;
            const sowCols                              = ref(DESKTOP_COLS);

            window.addEventListener('resize', debounce(function () {
                currentScreenWidth.value = window.screen.width;
                updateColsOrderInCaseOfMobileDesktop();
            }));
            const isMobileScreenSize    = computed(() => currentScreenWidth.value < SCREEN_TABLET_BREAKPOINT_WIDTH);
            const isSortFieldListActive = ref(false);
            const isNotFindModalShowed  = ref(null);
            const isHowThatWorksShowed  = ref(null);
            const isInfoForFarmInstitutionShowed  = ref(null);
            const valueChanged =  () => {
                showFilterResultData.value = !showFilterResultData.value;
            };

            if (!route.query.district) {
                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        'district': districts.value?.[0]?.district_name,
                    },
                });
            }

            computed(() => {
                return route.query;
            });

            document.addEventListener('click', () => {
                isSortFieldListActive.value = false;
            });

            onBeforeRouteUpdate((to, from, next) => {
                if (!to.query.district) {
                    router.push({
                        name: 'SearchView',
                        query: {
                            ...to.query,
                            'district': districts.value?.[0]?.district_name,
                        },
                    });
                }

                if (!to.query.search) {
                    store.commit('medicines/setSearchResult', {});
                }

                next()
            });

            watch(districts, (newValue) => {
                selectedDistrict.value = route.query.district || newValue?.[0]?.district_name;
            });

            watch(selectedDistrict, () => {
                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        'district': selectedDistrict.value,
                    },
                });
            });

            const gridColumns = computed(() => {
                // create array with unique values
                const totalCols = sowCols.value.concat(
                    additionColumns.value.filter(
                        (item) => sowCols.value.indexOf(item) < 0
                    )
                );

                return totalCols.map((item) => {
                    return resultColumns.value.find(resCol => resCol.name === item)
                }).filter(item => item);
            });

            const isFilterResultDataVisible = computed(() => {
                return gridColumns.value.length && filterResultData.value.length && showFilterResultData.value;
            })

            const isResultDataVisible = computed(() => {
                return gridColumns.value.length && resultData.value.length && !showFilterResultData.value;
            })

            const changePage = (link) => {
                const url          = new URL(link.url);
                const searchParams = new URLSearchParams(url.search);
                const queryParams  = {};

                for (let [param, value] of searchParams) {
                    queryParams[param] = value;
                }

                queryParams.district = selectedDistrict.value;

                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        page: queryParams.page,
                    },
                });

                store.dispatch('medicines/search', queryParams);
            };


            const searchQueryUrl = computed({
                get() {
                    let result = {};

                    if (route.query.search) {
                        const searchParams = route.query.search.split('__');

                        if (searchParams.length === 1 && !searchParams[0].includes('::')) {
                            result = searchParams[0]
                        } else {
                            searchParams.forEach((searchParam) => {
                                const [prop, value] = searchParam.split('::');

                                result[prop] = value;
                            });
                        }
                    }

                    return result;
                },
                set() {
                    console.log('set searchQueryUrl')
                },
            });

            const searchQueryView = computed({
                get() {
                    let result = [];

                    if (typeof searchQueryUrl.value === 'string') {
                        result = searchQueryUrl.value;
                    } else {
                        for (let [prop, value] of Object.entries(searchQueryUrl.value)) {
                            const propTranslation = store.getters['NAMES'](prop)?.fieldNameTranslation;

                            if (propTranslation) {
                                result.push(propTranslation + '::' + value);
                            }
                        }

                        result = result.join(' ');
                    }

                    return result;
                },
                set(payload) {
                    let [prop, value] = payload.split('::');
                    let search        = '';

                    if (!value) {
                        if (payload.endsWith('::')) {
                            search = prop + '::';
                        } else {
                            search = prop;
                        }
                    } else {
                        search = (store.getters['NAMES'](prop).fieldNameUrl || prop) + '::' + value;
                    }

                    router.push({
                        name: 'SearchView',
                        query: {
                            ...route.query,
                            'search': search,
                        },
                    });
                },
            });

            const clearSearchString = () => {
                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        'search': null,
                    },
                });

                searchString.value = '';
            };

            const onAdvancedSearchBtnClick = async () => {
                enabledQrCode.value = true;
            };

            let geolocationFlag = false;

            const onInput = debounce((payload) => {
                if (!geolocationFlag){
                    navigator.geolocation.watchPosition( c=> console.log(c));
                    geolocationFlag = true;
                }
                let [prop, value] = payload.value.split('::');
                let search        = '';

                if (!value) {
                    search = prop;
                } else {
                    search = value;
                }

                if ((value && value.length >= 3) || (search.length >= 3)) {
                    store.dispatch('medicines/getSuggestions', {
                        order: 'mnn',
                        search: search,
                        sg_number: 5,
                    });
                }
            });

            const onSelect = (payload) => {
                const search = payload[Object.keys(payload.search_result)[0]];

                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        'search': encodeSearchQuery({
                            [Object.keys(payload.search_result)[0]]: search,
                        }),
                    },
                });

                searchString.value = Object.values(payload.search_result)[0].ru_name + '::' + search;
            };

            const onSearch = async () => {
                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        page: 1,
                        order: 'data_vstuplenia_w_sily',
                        sort: 'desc',
                    },
                });

                await store.dispatch('medicines/search', {
                    order: 'data_vstuplenia_w_sily',
                    sort: 'desc',
                    search: route.query.search,
                    per_page: 30,
                    page: 1,
                    district: selectedDistrict.value,
                });

                if (tableRef.value) {
                    tableRef.value.$el.scrollIntoView();
                }

                if (!searchResult?.value?.search_results?.data || searchResult.value.search_results.data.length === 0) {
                    isNotFindModalShowed.value = true;

                    setTimeout(() => {
                        if (isNotFindModalShowed.value) {
                            isNotFindModalShowed.value = false;
                        }
                    }, 5000);
                }
            };

            const onSuggestionSubmit = async (payload) => {
                const search = payload[Object.keys(payload.search_result)[0]];

                searchString.value = Object.values(payload.search_result)[0].ru_name + '::' + search;

                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        'search': encodeSearchQuery({
                            [Object.keys(payload.search_result)[0]]: search,
                        }),
                        page: 1,
                    },
                });

                await store.dispatch('medicines/search', {
                    order: 'mnn',
                    search: search,
                    per_page: 30,
                    page: 1,
                    district: selectedDistrict.value,
                });

                if (tableRef.value) {
                    tableRef.value.$el.scrollIntoView();
                }

                if (!searchResult?.value?.search_results?.data || searchResult.value.search_results.data.length === 0) {
                    isNotFindModalShowed.value = true;

                    setTimeout(() => {
                        if (isNotFindModalShowed.value) {
                            isNotFindModalShowed.value = false;
                        }
                    }, 5000);
                }
            };

            const encodeSearchQuery = (searchQuery) => {
                return Object.keys(searchQuery).map((item) => {
                    return getValue(item) + '::' + searchQuery[item];
                }).join('__');
            };

            onMounted(() => {
                if (route.query.search) {
                    const query = {
                        search: route.query.search,
                        per_page: 30,
                    };

                    if (route.query.order) {
                        query.order = route.query.order;
                    }

                    if (route.query.sort) {
                        query.sort = route.query.sort;
                    }

                    if (route.query.district) {
                        query.district = route.query.district;
                    }

                    if (route.query.page) {
                        query.page = route.query.page;
                    } else {
                        query.page = 1;

                        router.push({
                            name: 'SearchView',
                            query: {
                                ...route.query,
                                page: 1,
                            },
                        });
                    }

                    store.dispatch('medicines/search', query);
                }
                updateColsOrderInCaseOfMobileDesktop();
            });


            const changeOrder = () => {
                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        sort: selectedSort.value === 'asc' ? 'desc' : 'asc',
                    },
                });

                store.dispatch('medicines/search', {
                    order: selectedSortField.value.name,
                    sort: selectedSort.value === 'asc' ? 'desc' : 'asc',
                    search: route.query.search,
                    per_page: 30,
                    page: 1,
                    district: selectedDistrict.value,
                });
            };

            const changeSortField = (payload) => {
                router.push({
                    name: 'SearchView',
                    query: {
                        ...route.query,
                        order: payload.name,
                    },
                });

                store.dispatch('medicines/search', {
                    order: payload.name,
                    sort: selectedSort.value,
                    search: route.query.search,
                    per_page: 30,
                    page: 1,
                    district: selectedDistrict.value,
                });
            };

            const enabledQrCode = ref(false);

            return {
                searchString,
                suggestions,
                suggestionsLoading,
                onInput,
                onSelect,
                onSearch,
                resultColumns,
                resultData,
                filterResultData,
                isFilterResultDataVisible,
                isResultDataVisible,
                valueChanged,
                showFilterResultData,
                resultLinks,
                gridColumns,
                additionColumns,
                changePage,
                searchQueryUrl,
                searchQueryView,
                selectedDistrict,
                districts,
                registry,
                searchInputRef,
                onSuggestionSubmit,
                tableRef,
                clearSearchString,
                onAdvancedSearchBtnClick: onAdvancedSearchBtnClick,
                selectedSort,
                selectedSortField,
                isSortFieldListActive,
                changeOrder,
                changeSortField,
                sortListButton,
                isNotFindModalShowed,
                isHowThatWorksShowed,
                isInfoForFarmInstitutionShowed,
                enabledQrCode,
                onScan(decodedText) {
                    enabledQrCode.value   = false;
                    searchQueryView.value = decodedText;
                },
                linkDZTO,
            };
        },
    };
</script>

<style lang="scss">
    .search-view {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 15px;

        @media screen and (min-width: $breakpoint_tablet) {
            padding: 30px;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            padding: 50px;
        }
    }

    .search-view__description {
        font-size: 0.75rem;
        max-width: 1200px;
        margin-bottom: 15px;
        text-align: center;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1rem;
            margin-bottom: 30px;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            font-size: 1.25rem;
            margin-bottom: 45px;
        }
    }

    .search-view__title {
        flex-basis: 100%;
        margin: 0 15px 15px;

        @media screen and (min-width: $breakpoint_tablet) {
            margin-bottom: 30px;
        }
    }

    .search-view__title-icon {
        width: 46px;

        @media screen and (min-width: $breakpoint_tablet) {
            width: 68px;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            width: 102px;
        }
    }

    .search-view__content-wrapper {
        @media screen and (min-width: $breakpoint_desktop) {
            display: flex;
            flex-wrap: wrap;
            max-width: 1060px;
            margin-left: 20px;
            padding-right: 40px;
            padding-left: 60px;
        }
    }

    .search-view__search-input {
        width: 100%;
        margin-bottom: 15px;

        @media screen and (min-width: $breakpoint_tablet) {
            margin-bottom: 25px;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            flex-basis: 72.2%;
            order: 1;
            width: auto;
            margin-bottom: 30px;
        }
    }

    #radio1, #radio2 {
        position: absolute;
        left: -9999px;
    }

    .search-view__district {
        @media screen and (min-width: $breakpoint_tablet) {
            display: flex;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            flex-basis: 75.5%;
            order: 3;
            gap: 30px;
        }
    }

    .search-view__district_tomsk {
        @media screen and (min-width: $breakpoint_tablet) {
            flex-basis: 27%;
        }
    }

    .search-view__district_subject {
        @media screen and (min-width: $breakpoint_tablet) {
            flex-basis: 63%;
        }
    }

    .search-view__district_label {
        font-size: 0.75rem;
        font-weight: 500;
        padding-right: 80px;
        cursor: pointer;

        &::before {
            position: absolute;
            display: inline-block;
            box-sizing: border-box;
            width: 24px;
            height: 24px;
            margin-right: 0.5em;
            content: "";
            border: 1px solid $district-radio-icon__border-color;
            border-radius: 100px;
            background: $district-radio-icon__background;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            font-size: 0.875rem;

            &::before {
                width: 30px;
                height: 30px;
            }
        }
    }

    .search-view__district-title {
        padding-left: 33px;

        @media screen and (min-width: $breakpoint_desktop) {
            padding-left: 39px;
        }

        span {
            font-weight: 400;
        }
    }

    #radio1:checked + .search-view__district_label::before,
    #radio2:checked + .search-view__district_label::before {
        background: $district-radio-icon__background_checked;

        @media screen and (min-width: $breakpoint_desktop) {
            background: $district-radio-icon__background_desktop_checked;
        }
    }

    .search-view__button {
        margin-bottom: 20px;
        transition: box-shadow .3s ease-out;

        @media screen and (min-width: $breakpoint_desktop) {
            flex-basis: 21%;
            order: 2;
            margin-bottom: 0;
            margin-left: 23px;
        }

        &:hover, &:focus {
            box-shadow: $search-button__box-shadow_hover;
        }
    }

    .search-view__additional-fields-block {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        @media screen and (min-width: $breakpoint_tablet) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            flex-wrap: wrap;
            order: 7;
            margin-right: -40px;
            gap: 22px;
        }

        @media screen and (max-width: $breakpoint_tablet) {
            flex-direction: column;
            flex-wrap: wrap;
        }
    }

    .how-it-works__container, .info-for-farm-institution__container {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        flex-direction: column;
        max-width: 100%;
        padding: 20px 0 30px 0;

        @media screen and (min-width: $breakpoint_desktop) {
            order: 4;
        }

        .how-it-works__button {
            font-size: 1.125rem;
            font-weight: 700;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: color 0.2s ease-out;
            text-decoration: underline;
            color: $how-it-works__text-color;

            &:hover {
                color: $how-it-works__text-color_hover;
            }

            .how-it-works__button-icon {
                max-width: 50px;
                max-height: 50px;
                margin-right: 10px;
            }
        }

        .info-for-farm-institution__button {
            font-size: 1.125rem;
            font-weight: 700;
            display: flex;
            text-align: center;
            cursor: pointer;
            transition: color 0.2s ease-out;
            text-decoration: underline;
            color: $how-it-works__text-color;

            &:hover {
                color: $how-it-works__text-color_hover;
            }
        }

        .how-it-works__content, .info-for-farm-institution__content {
            max-width: 100%;
        }
    }

    .search-view_dzto__container {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        flex-direction: column;
        max-width: 100%;
        padding: 20px 0 30px 0;

        @media screen and (min-width: $breakpoint_desktop) {
            order: 5;
            max-width: 95%;
        }

        .search-view_dzto__link {
            display: inline-block;
            font-size: 0.8rem;
            font-weight: 700;
            max-width: 800px;
            margin-bottom: 15px;
            text-align: center;
            align-items: center;


            @media screen and (min-width: $breakpoint_tablet) {
                font-size: 0.9rem;
                margin-bottom: 10px;
            }

            @media screen and (min-width: $breakpoint_desktop) {
                font-size: 1.1rem;
                margin-bottom: 20px;
            }
        }
    }

    .additional-fields-block__title {
        font-size: 0.875rem;
        font-weight: 500;
        flex-basis: 100%;
        margin-bottom: 15px;
        margin-top: 15px;

        @media screen and (min-width: $breakpoint_desktop) {
            order: 6;
        }
    }

    .additional-fields-block__addition-field {
        &:not(:last-child) {
            margin-bottom: 15px;
        }

        @media screen and (min-width: $breakpoint_tablet) {
            flex-basis: 50%;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            flex-basis: auto;
        }
    }

    .additional-fields-block__addition-field_barcode {
        white-space: nowrap;
    }

    .additional-fields-block__addition-field input[type="checkbox"] {
        position: absolute;
        left: -999em;

        & + label {
            cursor: pointer;

            &:hover {
                color: $addition-field__text-color_hover;

                .check-box {
                    // background-color: red;
                }

                .check-mark {
                    position: absolute;
                    top: 0;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    transform: rotate(45deg) translate(-2px, -7px);
                    opacity: 0.3;

                    &::before {
                        z-index: 44;
                        display: block;
                        width: 13px;
                        height: 5px;
                        margin-right: -2px;
                        content: "";
                        border-radius: 2px;
                        background: $addition-field__check-mark_hover;
                    }

                    &::after {
                        z-index: 44;
                        display: block;
                        width: 5px;
                        height: 30px;
                        content: "";
                        border-radius: 2px;
                        background: $addition-field__check-mark_hover;
                    }
                }
            }
        }
    }

    .additional-fields-block__addition-field input[type="checkbox"]:checked {
        & + label .check-mark {
            position: absolute;
            top: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 100%;
            height: 100%;
            transform: rotate(45deg) translate(-2px, -7px);
            opacity: 1;

            &::before {
                z-index: 44;
                display: block;
                width: 13px;
                height: 5px;
                margin-right: -2px;
                content: "";
                border-radius: 2px;
                background: $addition-field__check-mark;
            }

            &::after {
                z-index: 44;
                display: block;
                width: 5px;
                height: 30px;
                content: "";
                border-radius: 2px;
                background: $addition-field__check-mark;
            }
        }

        & + label p {
            color: $addition-field__text-color_checked;
        }
    }

    .additional-fields-block__addition-field p {
        font-size: 0.875rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        min-height: 30px;
        padding-left: 42px;
    }

    .check-box {
        position: relative;
        position: absolute;
        display: block;
        display: inline-block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        border: 1px solid $addition-field__check-box__border-color;
        border-radius: 7px;
        background: #ffffff;
    }


    .search-view__table-row-link {
        font-size: 1rem;
        font-weight: 700;
        width: 100%;
        height: 100%;
        text-align: center;
        color: $table-link__text-color;

        &:hover {
            color: $table-link__text-color_hover;
        }
    }

    .search-view__table {
        flex-basis: 100%;
        margin-bottom: 15px;
    }

    .search-view__pagination {
        display: flex;
        justify-content: center;
    }


    .search-view__sort {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        justify-content: left;

        @media screen and (min-width: $breakpoint_desktop) {
            width: 70%;
        }
    }

    .search-view__sort-image {
        margin-right: 10px;
        cursor: pointer;
        transition: transform .3s 0s ease-out;

        &.desc {
            transform: rotateX(180deg);
        }
    }

    .search-view__sort-field {
        font-family: inherit;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        color: inherit;
        border: none;
        outline: none;
        background-color: transparent;
    }

    .search-view__sort-field-list {
        position: absolute;
        z-index: 2;
        top: 40px;
        left: 0;
        width: 100%;
        max-width: 500px;
        transition: transform .2s .1s ease-out;
        transform: scaleY(0);
        transform-origin: top;
        border: 1px solid $sort-field__border-color;
        border-radius: 7px;
        background-color: $sort-field__background;

        &.active {
            transform: scaleY(1);
        }

        @media screen and (min-width: $breakpoint_desktop) {
            left: 165px;
        }
    }


    .search-view__sort-field-list-item {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 10px 15px;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid $sort-field__border-color;
        }

        &:hover, &.active {
            color: $sort-field__text-color;
            background: $sort-field__background_hover;
        }
    }

    .search-view__sort-label {
        display: none;

        @media screen and (min-width: $breakpoint_desktop) {
            font-size: 1rem;
            font-weight: 700;
            padding-right: 20px;
            display: flex;
        }
    }
</style>
