import {ref} from 'vue';

const state = {
    grecaptcha: ref(null),
    siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
};

const mutations = {
    SET_GRECAPTCHA(state, payload) {
        state.grecaptcha = payload;
    },
};

const getters = {
    GRECAPTCHA(state) {
        return state.grecaptcha;
    },
    SITE_KEY(state) {
        return state.siteKey;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
