<template>
    <button
        :class="{
            'transparent-background': transparentBackground,
        }"
        @click.prevent="onClick"
        class="button-component"
    >
        {{ text }}
    </button>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: '',
            },
            transparentBackground: {
                type: Boolean,
                default: false,
            },
        },
        emits: {
            'click': () => true,
        },
        setup(props, context) {
            const onClick = () => {
                context.emit('click', ...arguments);
            };

            return {onClick};
        },
    };
</script>


<style lang="scss">
    .button-component {
        font-size: 0.9375rem;
        font-weight: 600;
        width: 100%;
        height: 50px;
        cursor: pointer;
        color: $button__color;
        border: $button__border;
        border-radius: 10px;
        outline: none;
        background: $button__background;
        box-shadow: $button__box-shadow;

        @media screen and (min-width: $breakpoint_tablet) {
            font-size: 1.125rem;
        }

        @media screen and (min-width: $breakpoint_desktop) {
            height: 60px;
        }

        &.transparent-background {
            color: $button__transparent__color;
            border: 2px solid $button__transparent__border-color;
            background: $button__transparent__background;
        }
    }
</style>
